import React, { useState, useRef, useEffect } from 'react';

type EmojiPickerProps = {
  onEmojiSelect: (emoji: string) => void;
};

const EmojiPicker: React.FC<EmojiPickerProps> = ({ onEmojiSelect }) => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsNavbarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const emojiCategories: { [key: string]: string[] } = {
    'Smileys & Emotion': [
      "😀", "😂", "😍", "😭", "😡", "🥰", "😱", "🤔", "😴", "🤯", "🥳", "😓", "😉", "🙃", "😇",
      "😃", "😄", "😁", "😆", "😅", "🤣", "😊", "😋", "😎", "🤓", "😜", "😝", "😛", "🤗", "🤩",
      "😤", "😠", "🤬", "😈", "👿", "💀", "☠️", "🤡", "💩", "👻", "👽", "👾", "🤖", "🎃", "😺"
    ],
    'People & Body': [
      "👍", "💪", "👏", "🙏", "💅", "🤳", "👫", "🧑‍🤝‍🧑", "🧘", "🚶", "👷‍♂️", "🕵️‍♀️", "💂‍♂️", "👩‍⚕️", "👨‍🍳",
      "🙍‍♂️", "🙍‍♀️", "🙎‍♂️", "🙎‍♀️", "🙅‍♂️", "🙅‍♀️", "🙆‍♂️", "🙆‍♀️", "💁‍♂️", "💁‍♀️", "🙋‍♂️", "🙋‍♀️", "🧏‍♂️", "🧏‍♀️", "🙇‍♂️",
      "🙇‍♀️", "🤦‍♂️", "🤦‍♀️", "🤷‍♂️", "🤷‍♀️", "👨‍⚖️", "👩‍⚖️", "👨‍🌾", "👩‍🌾", "👨‍🍳", "👩‍🍳", "👨‍🎓", "👩‍🎓", "👨‍🎤", "👩‍🎤"
    ],
    'Animals & Nature': [
      "🚀", "🌳", "🐶", "🐱", "🦄", "🦋", "🐝", "🌹", "🌍", "🪴", "🌵", "🍁", "🍄", "🐉", "🦕",
      "🐀", "🐁", "🐭", "🐹", "🐰", "🐇", "🐿️", "🦔", "🦇", "🐻", "🐨", "🐼", "🦥", "🦦", "🦨",
      "🦘", "🦡", "🐾", "🐉", "🐲", "🌵", "🎄", "🌲", "🌳", "🌴", "🌱", "🌿", "☘️", "🍀", "🎍"
    ],
    'Food & Drink': [
      "🍎", "🍕", "🍔", "🍣", "🍺", "🥑", "🍰", "☕", "🍜", "🍌", "🥥", "🧀", "🍖", "🍩", "🍪",
      "🍉", "🍇", "🍓", "🍈", "🍒", "🍑", "🍍", "🥭", "🍆", "🥔", "🥕", "🌽", "🌶️", "🫑", "🥒",
      "🥬", "🥦", "🧄", "🧅", "🍄", "🥜", "🌰", "🍞", "🥖", "🥨", "🥯", "🥞", "🧇", "🧈", "🍗"
    ],
    'Travel & Places': [
      "🚗", "✈️", "🚉", "🏝️", "🏔️", "🗽", "🏰", "🛤️", "🌆", "🚢", "🌉", "🎡", "🏜️", "🌋", "🗼",
      "🏠", "🏡", "🏘️", "🏚️", "🏢", "🏬", "🏣", "🏤", "🏥", "🏦", "🏨", "🏪", "🏫", "🏩", "💒",
      "🏛️", "⛪", "🕌", "🕍", "🛕", "🕋", "⛲", "⛺", "🌁", "🌃", "🏙️", "🌄", "🌅", "🌆", "🌇"
    ],
    'Activities': [
      "⚽", "🏀", "🏈", "🎾", "🎮", "🎲", "🏋️‍♂️", "🚴‍♀️", "🏊‍♂️", "🧗‍♀️", "🎣", "🏹", "🎿", "🛹", "🏎️",
      "🏇", "🏅", "🥇", "🥈", "🥉", "🏆", "🎽", "🎳", "🎯", "🎱", "🎟️", "🎫", "🎭", "🎨", "🎬",
      "🎤", "🎧", "🎼", "🎹", "🥁", "🎷", "🎺", "🎸", "🎻", "🎲", "🎮", "🎰", "🎲", "🎯", "🎳"
    ],
    'Objects': [
      "💻", "📱", "📸", "🎥", "🔑", "🎒", "🕰️", "🔦", "🧲", "🔔", "📚", "🔨", "🛠️", "🧵", "🧶",
      "🖥️", "🖨️", "🖱️", "🖲️", "🕹️", "💽", "💾", "💿", "📀", "📼", "📷", "📹", "📼", "🔍", "🔎",
      "🗂️", "🗃️", "🗄️", "🗑️", "🛒", "💸", "💵", "💴", "💶", "💷", "💰", "💳", "🧾", "📜", "📃"
    ],
    'Symbols': [
      "❤️", "✨", "🔥", "🎵", "💲", "🔒", "🚫", "✔️", "♻️", "💯", "⚠️", "🚸", "📵", "♾️", "🕉️",
      "☮️", "✝️", "☪️", "🕉️", "☸️", "✡️", "🔯", "🕎", "☯️", "☦️", "🛐", "⛎", "♈", "♉", "♊",
      "♋", "♌", "♍", "♎", "♏", "♐", "♑", "♒", "♓", "⛎", "🔀", "🔁", "🔂", "⏩", "⏭️"
    ], 'Flags': [
      "🏁", "🚩", "🇺🇸", "🇬🇧", "🇯🇵", "🇩🇪", "🇧🇷", "🇮🇳", "🇨🇳", "🇫🇷", "🇨🇦", "🇦🇺", "🇮🇹", "🇷🇺", "🇪🇸",
      "🇦🇫", "🇦🇱", "🇩🇿", "🇦🇩", "🇦🇴", "🇦🇷", "🇦🇲", "🇦🇹", "🇦🇿", "🇧🇸", "🇧🇭", "🇧🇩", "🇧🇧", "🇧🇾", "🇧🇪",
      "🇧🇿", "🇧🇯", "🇧🇹", "🇧🇴", "🇧🇼", "🇧🇳", "🇧🇬", "🇧🇫", "🇧🇮", "🇨🇻", "🇰🇭", "🇨🇲", "🇨🇫", "🇹🇩", "🇨🇱"
    ]
  };

  return (
    <div className="d-flex" ref={ref}>
      <button
        type="button"
        onClick={toggleNavbar}
        className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 w-6 text-gray-600"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
      {isNavbarVisible && (
        <div className="absolute mt-2 w-72 bg-white border border-gray-300 rounded-lg shadow-lg p-4 z-10" style={{ top: selectedCategory === "" ? "290px" : "150px", maxHeight: '300px', overflowY: 'auto' }}>
          <div className="fixed">
            <select
              onChange={handleCategoryChange}
              className="p-10 py-2 pl-3 pr-10 text-left bg-white rounded-md shadow-md cursor-pointer focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
              value={selectedCategory}
            >
              <option value="">Selecione uma Categoria</option>
              {Object.keys(emojiCategories).map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          <div className="mt-10 grid grid-cols-5 gap-2">
            {selectedCategory && emojiCategories[selectedCategory]?.map((emoji: string) => (
              <button
                key={emoji}
                onClick={() => onEmojiSelect(emoji)}
                className="text-xl p-2 hover:bg-gray-200 rounded-md"
              >
                {emoji}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmojiPicker;