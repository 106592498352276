import api from "./Api";

const QrCodeChatPro = () => {
    return api.get("/chatpro/cliente/generate/qrcode").then((response: any) => {
        return response;
    });
};

const getStatus = () => {
    return api.get("/chatpro/cliente/status")
        .then(response => response)
        .catch(error => console.error("Erro ao obter o status:", error));
};

const reload = () => {
    return api.get("/chatpro/cliente/reload")
        .then(response => response)
        .catch(error => console.error("Erro ao recarregar a sessão:", error));
};

const removeSession = () => {
    return api.get("/chatpro/cliente/remove/session")
        .then(response => response)
        .catch(error => console.error("Erro ao remover a sessão:", error));
};

const getProfile = (data : any) => {
    return api.post("/chatpro/cliente/get/profile", data)
        .then(response => response)
        .catch(error => console.error("Erro ao obter o perfil:", error));
};

const listContacts = () => {
    return api.get("/chatpro/cliente/contacts")
        .then(response => response)
        .catch(error => console.error("Erro ao listar contatos:", error));
};

const listChats = () => {
    return api.get("/chatpro/cliente/chats")
        .then(response => response)
        .catch(error => console.error("Erro ao listar chats:", error));
};

export default {
    QrCodeChatPro,
    getStatus,
    reload,
    removeSession,
    getProfile,
    listContacts,
    listChats,
};
