
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../App.css';
import { useLocation } from 'react-router-dom';
import BarChart from '../components/Dashboard/BarChart';
import PieChart from '../components/Dashboard/PieChart';
import LineChart from '../components/Dashboard/LineChart';
import DoughnutChart from '../components/Dashboard/DoughnutChart';
import Cookies from 'universal-cookie';

function Dashboard() {


    const cookies = new Cookies();
    const location = useLocation();
    let navigate = useNavigate();
    useEffect(() => {

        console.log(cookies.get('user').roles[0].name);
    }, []);

        return (
            <>
                <div className="h-screen">
                    <div className="min-h-screen bg-gray-50/50">
    
                        <div className="p-4">
    
                            <div className="mt-12">
                                <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                                {(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ??
                                    <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                                        <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-green-600 to-green-400 text-white shadow-green-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-6 h-6 text-white">
                                                <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                                                <path fillRule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z" clipRule="evenodd" />
                                                <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z" />
                                            </svg>
                                        </div>
                                        <div className="p-4 text-right">
                                            <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Total Revenue</p>
                                            <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">$53k</h4>
                                        </div>
                                        <div className="border-t border-blue-gray-50 p-4">
                                            <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                                                <strong className="text-green-500">+13%</strong>&nbsp;than last month
                                            </p>
                                        </div>
                                    </div>
                                }
                                    <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                                        <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-indigo-600 to-indigo-400 text-white shadow-indigo-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-6 h-6 text-white">
                                                <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <div className="p-4 text-right">
                                            <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">{(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ?  "Total Customers" : "Total clients served" }</p>
                                            <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">12,300</h4>
                                        </div>
                                        <div className="border-t border-blue-gray-50 p-4">
                                            <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                                                <strong className="text-green-500">+3%</strong>&nbsp;than last month
                                            </p>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                                        <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-6 h-6 text-white">
                                                <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                                            </svg>
                                        </div>
                                        <div className="p-4 text-right">
                                            <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">{(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ?  "New Customers" : "New clients served" }</p>
                                            <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">3,462</h4>
                                        </div>
                                        <div className="border-t border-blue-gray-50 p-4">
                                            <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                                                <strong className="text-green-500">+20%</strong>&nbsp;than last month
                                            </p>
                                        </div>
                                    </div>
                                    {(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ??
                                    <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                                        <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-6 h-6 text-white">
                                                <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
                                            </svg>
                                        </div>
                                        <div className="p-4 text-right">
                                            <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"> Monthly Revenue</p>
                                            <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">$103,430</h4>
                                        </div>
                                        <div className="border-t border-blue-gray-50 p-4">
                                            <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                                                <strong className="text-green-500">+23%</strong>&nbsp;than last month
                                            </p>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                                    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                                        <div className="flex flex-wrap items-center">
                                            <div className="relative w-full max-w-full flex-grow flex-1">
                                                <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                                                    Performance
                                                </h6>
                                                <h2 className="text-blueGray-700 text-xl font-semibold">
                                                    Total contracted plans
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-2 flex-auto">
                                        <div className="relative lg:h-550-px md:h-550-px sm:h-550-px xl:h-550-px">
                                            <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                                                <div className="place-items-center">
    
                                                    <BarChart
                                                        arrayLabels={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                                                        labels='Monthly fees' data={[5, 65, 80, 45, 60, 75, 90, 55, 70, 35, 70, 85]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 flex-auto">
                                        <div className={`flex grid-cols-1 sm:grid-cols-1 md:grid-cols-1 ${cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin" ? 'lg:grid-cols-3': 'lg:grid-cols-2 items-center justify-center'}`} >
                                        {(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ??
                                             <div className="relative h-550-px flex-auto 5 mb-4 overflow-auto custom-scrollbar"> 
                                                <div className="relative bg-clip-border rounded-xl bg-white text-gray-700">
                                                    <div className="flex flex-col-3">
                                                        <div className="place-items-center">
                                                            <div className="relative w-full max-w-full flex-grow flex-1 mb-4">
                                                                <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                                                                    Total contracted plans
                                                                </h6>
                                                                <h2 className="text-blueGray-700 text-xl font-semibold">
                                                                    Last Month
                                                                </h2>
                                                            </div>
                                                            <DoughnutChart arrayLabels={['Plan Basic', 'Plan Team', 'Plan Company', 'Plan Custom']} data={[30, 40, 25, 10]} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                            <div className="relative h-550-px flex-auto mb-4 overflow-auto custom-scrollbar">
                                                <div className="relative bg-clip-border rounded-xl bg-white text-gray-700 ">
                                                    <div className="flex flex-col-3">
                                                        <div className="place-items-center">
                                                            <div className="relative w-full max-w-full flex-grow flex-1 mb-4">
                                                                <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                                                                {(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ?  "Total contracted plans" : "Total clients served" }
                                                                </h6>
                                                                <h2 className="text-blueGray-700 text-xl font-semibold">
                                                                    Last Week
                                                                </h2>
                                                            </div>
                                                            <PieChart arrayLabels={['Plan Basic', 'Plan Team', 'Plan Company', 'Plan Custom']} data={[6, 5, 4, 1]} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative h-550-px flex-auto mb-4 overflow-auto custom-scrollbar"> {/* Alterado para overflow-auto */}
                                                <div className="relative bg-clip-border rounded-xl bg-white text-gray-700">
                                                    <div className="flex flex-col-3">
                                                        <div className="place-items-center">
                                                            <div className="relative w-full max-w-full flex-grow flex-1 mb-4">
                                                                <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                                                                {(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ?  "Total contracted plans" : "Total clients served" }
                                                                </h6>
                                                                <h2 className="text-blueGray-700 text-xl font-semibold">
                                                                    This Week
                                                                </h2>
                                                            </div>
                                                            <DoughnutChart arrayLabels={['Plan Basic', 'Plan Team', 'Plan Company', 'Plan Custom']} data={[30, 40, 25, 10]} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
    
                                </div>
                            </div>
                            <div className="text-blue-gray-600">
                                <footer className="py-2">
                                    <div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 md:justify-between">
                                        <p className="block antialiased font-sans text-sm leading-normal font-normal text-inherit">© 2023, made with <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="-mt-0.5 inline-block h-3.5 w-3.5">
                                            <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                                        </svg> by <button onClick={() => navigate("/")} className="transition-colors hover:text-blue-500">Creative Tim</button> for a better web. </p>
                                        <ul className="flex items-center gap-4">
                                            <li>
                                                <button onClick={() => navigate("/")}  className="block antialiased font-sans text-sm leading-normal py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500">Creative Tim</button>
                                            </li>
                                            <li>
                                                <button onClick={() => navigate("/")}  className="block antialiased font-sans text-sm leading-normal py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500">About Us</button>
                                            </li>
                                            <li>
                                                <button onClick={() => navigate("/")} className="block antialiased font-sans text-sm leading-normal py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500">Blog</button>
                                            </li>
                                            <li>
                                                <button onClick={() => navigate("/")} className="block antialiased font-sans text-sm leading-normal py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500">License</button>
                                            </li>
                                        </ul>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    
   
}
export default Dashboard;
