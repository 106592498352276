import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useNavigate } from 'react-router-dom';

function NotificationList() {

    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {


    }, []);
    return (
        <>
            <div className="h-screen w-screen">
                <div className=" bg-white flex flex-col bg-clip-border rounded-xl m-2">
                    <div className="p-4 flex sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5  ">
                        <div className="w-full mb-1">
                            <div className="mb-4">
                                <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                                    Listing Notifications
                                </h1>
                            </div>
                            <div className="sm:flex">
                                <div className="items-center sm:flex-cols-1 flex mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0">
                                    <label htmlFor="Notifications-search" className="sr-only">
                                        Search
                                    </label>
                                    <div className="relative mt-1 lg:w-64 xl:w-96">
                                        <input
                                            type="text"
                                            name="email"
                                            id="Notifications-search"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="Search for Notifications"
                                        />
                                    </div>
                                    <div className="relative h-10 w-72 min-w-[200px]">
                                        <select className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50">
                                            <option value="All">All</option>
                                            <option value="Unread">Unread</option>
                                            <option value="Read">Read</option>
                                        </select>
                                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                            Select Status
                                        </label>
                                    </div>
                                    <button

                                        className="inline-flex justify-center p-1 text-dark rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100   "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            width={20}
                                            height={20}
                                            viewBox="0 0 48 48"
                                        >
                                            <path
                                                fill="#616161"
                                                d="M34.6 28.1H38.6V45.1H34.6z"
                                                transform="rotate(-45.001 36.586 36.587)"
                                            />
                                            <path fill="#616161" d="M20 4A16 16 0 1 0 20 36A16 16 0 1 0 20 4Z" />
                                            <path
                                                fill="#37474F"
                                                d="M36.2 32.1H40.2V44.400000000000006H36.2z"
                                                transform="rotate(-45.001 38.24 38.24)"
                                            />
                                            <path fill="#64B5F6" d="M20 7A13 13 0 1 0 20 33A13 13 0 1 0 20 7Z" />
                                            <path
                                                fill="#BBDEFB"
                                                d="M26.9,14.2c-1.7-2-4.2-3.2-6.9-3.2s-5.2,1.2-6.9,3.2c-0.4,0.4-0.3,1.1,0.1,1.4c0.4,0.4,1.1,0.3,1.4-0.1C16,13.9,17.9,13,20,13s4,0.9,5.4,2.5c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2C27.2,15.3,27.2,14.6,26.9,14.2z"
                                            />
                                        </svg>

                                    </button>
                                    <div className="flex ">

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto custom-scrollbar ">
                            <div className="inline-block min-w-full align-middle">
                                <div className="overflow-hidden shadow h-full">
                                    <table className="min-w-full divide-y divide-gray-200 table-fixed ">
                                        <thead className="bg-gray-100 ">
                                            <tr>

                                                <th
                                                    scope="col"
                                                    className="p-4 text-xs font-medium text-left text-gray-500 uppercase "
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="p-4 text-xs font-medium text-left text-gray-500 uppercase "
                                                >
                                                    Title
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="p-4 text-xs font-medium text-left text-gray-500 uppercase "
                                                >
                                                    Description
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="p-4 text-xs font-medium text-left text-gray-500 uppercase "
                                                >
                                                    Status
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="p-4 text-xs font-medium text-left text-gray-500 uppercase "
                                                >
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200 h-auto">
                                            <tr className="hover:bg-gray-100 bg-blue-50">

                                                <td className="flex items-center p-4 mr-12 space-x-6 whitespace-nowrap ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="mt-2 h-5 w-5 text-blue-500">
                                                        <path fillRule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clipRule="evenodd" />
                                                    </svg>
                                                    <div className="text-sm font-normal text-gray-500 ">
                                                        <div className="text-base font-semibold text-gray-900">
                                                            Notification
                                                        </div>
                                                        <div className="text-sm font-normal text-gray-500 ">
                                                            Pre-subscriber has just sent a message
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="max-w-sm p-4 overflow-hidden text-base font-normal text-gray-500 truncate xl:max-w-xs ">
                                                    Attention, Administrator!
                                                </td>
                                                <td className="p-4 text-xs font-medium text-gray-900 whitespace-nowrap">
                                                    We have identified that a new pre-subscriber has just sent a message to your platform.
                                                    This is an excellent... {/* opportunity for engagement and initial conversation. 
                                                    We recommend checking the message and responding promptly to provide additional information about your services or to guide the user through the subscription process.  */}
                                                </td>
                                                <td className="p-4 text-xs font-medium text-gray-900 whitespace-nowrap">
                                                    <div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2" title='Unread notification.' />
                                                </td>

                                                <td className="p-4 space-x-2 whitespace-nowrap">
                                                    <button
                                                        type="button"
                                                        data-modal-toggle="edit-Notification-modal"
                                                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-blue-500 hover:bg-blue-400 focus:ring-4 focus:ring-primary-300"
                                                        onClick={() => { showModal === false ? setShowModal(true) : setShowModal(false) }}
                                                    >
                                                        {/*  <svg
                                                            className="w-4 h-4 mr-2"
                                                            fill="currentColor"
                                                            viewBox="0 0 20 20"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg> */}
                                                        Read Notification
                                                    </button>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky bottom-0 right-0 items-center w-full p-4 border-t border-gray-200 sm:flex sm:justify-between  ">
                        <div className="flex items-center mb-4 sm:mb-0">
                            <button
                                onClick={() => navigate("#")}
                                className="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100   "
                            >
                                <svg
                                    className="w-7 h-7"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                            <button
                                onClick={() => navigate("#")}
                                className="inline-flex justify-center p-1 mr-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100   "
                            >
                                <svg
                                    className="w-7 h-7"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                            <span className="text-sm font-normal text-gray-500 ">
                                Showing{" "}
                                <span className="font-semibold text-gray-900">
                                    1-1
                                </span>{" "}
                                of{" "}
                                <span className="font-semibold text-gray-900">
                                    1
                                </span>
                            </span>
                        </div>

                    </div>
                </div>

            </div>
            < Modal 
            show={showModal2}
            size={"large"}
            fullscreen={false} 
            colorbackground="bg-blue-500" title={
                <>
                    <div className='inline-flex'>
                        <h3 className="text-xl font-semibold">Read Notification </h3>
                        <div className='w-[140px] sm:w-[450px] md:w-[650px] lg:w-[650px] xl:w-[650px]'></div>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm"
                            data-modal-toggle="edit-Notification-modal"
                            onClick={() => { showModal === false ? setShowModal(true) : setShowModal(false) }}
                        >
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button></div>
                </>
            }
                list={
                    <>
                       
                      
                        <div className='inline-flex ml-4'>  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="mt-2 h-5 w-5 text-blue-500">
                            <path fillRule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clipRule="evenodd" />
                        </svg> <label className='ml-4'>Attention, Administrator!</label></div>
                        <label className="p-4 text-xs font-medium text-gray-900">
                            We have identified that a new pre-subscriber has just sent a message to your platform. <br/>
                            This is an excellent opportunity for engagement and initial conversation. <br/>
                            We recommend checking the message and responding promptly to provide additional information about your services or to guide the user through the subscription process.
                        </label>
                    </>
                }
                footer={
                    <>
                        <button
                            className=" text-white border-2 bg-green-500 hover:bg-primary-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                            type="submit"
                        >
                            Mark as read
                        </button>
                    </>
                }
            />
           


        </>
    );


}
export default NotificationList;