import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

interface SideBarProps {
  show: boolean; // Ou o tipo que você está usando para controlar a visibilidade
  setShow: (show: boolean) => void; // Ajuste de acordo com a função que você está passando
}

function SideBar({ setShow, ...props }: SideBarProps) { // Assumindo que você passa uma função `setShow` via props para controlar a visibilidade
  let navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const sidebarRef = useRef<HTMLDivElement>(null);

  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Verifica se o clique foi fora do componente
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        // Chama a função para atualizar o estado e esconder o SideBar
        setShow(false); // Aqui usamos a função `setShow` para esconder o SideBar
      }
    };

    // Adiciona o listener quando o componente é montado
    document.addEventListener('mousedown', handleClickOutside);

    // Limpa o evento quando o componente é desmontado
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShow]); // Adiciona `setShow` nas dependências do useEffect para ele reagir a mudanças dessa função
  
  
  // Função para navegar para o chat
  const clickChat = () => {
    navigate('/dashboard');
  };


  if(cookies.get('user') !== undefined){
   
  return (
    <>
        <aside ref={sidebarRef} className={`bg-gray-100 fixed inset-0 z-50 my-4 ml-4 mt-14 w-60 rounded-xl transition-transform duration-300 xl:translate-x-0 ${props['show'] === false ? 'hidden' : 'block absolute z-20'}`} >
      <div className="relative border-b border-white/20">
          <div className="flex items-center gap-4 py-4 px-8">
          <button className="flex flex-col text-1xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">
            CHAT TO FLOW
          </button>
        </div>
        </div>
        <div className="relative border-b border-white/20">
          <a className="flex items-center gap-4 py-4 px-8 bg-green-100">
            <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-dark">Control Painel</h6>
          </a>
          <hr></hr>
        </div>
        <div className="m-1">
          <ul className="mb-4 flex flex-col gap-1">
            {(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ??
              <li className="mx-3.5 mt-4 mb-2">
                <p className="block antialiased font-sans text-sm leading-normal  text-dark font-black uppercase opacity-75">Administration</p>
              </li>}
            <li>
              <button aria-current="page" onClick={() => navigate("/home")} className={`middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg hover:shadow-gray-400/40 w-full flex items-center gap-4 px-4 capitalize text-dark ${location.pathname === "/home" ? "shadow-md hover:shadow-lg active:opacity-[0.85] bg-gradient-to-tr from-gray-300 to-gray-200 shadow-gray-700/20 " : ""} `} >
                <svg
                  className="w-5 h-5  text-dark transition duration-75 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                </svg>
                <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Home</p>
              </button>
            </li>
            {cookies.get("user").roles[0].name === "master" ??
              <li>
                <button onClick={() => navigate('/adm-list')} className={`middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg  text-dark hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize ${location.pathname === "/adm-list" ? " shadow-md hover:shadow-lg active:opacity-[0.85] bg-gradient-to-tr from-gray-300 to-gray-200 shadow-gray-700/20 " : ""} `}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-5 h-5 text-inherit">
                    <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                  </svg>
                  <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">admins</p>
                </button>
              </li>
            }
            {cookies.get("user").roles[0].name === "master" ??
              <li>
                <button onClick={() => navigate("/plans-list")} className={`middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg  text-dark hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize ${location.pathname === "/plans-list" ? " shadow-md hover:shadow-lg active:opacity-[0.85] bg-gradient-to-tr from-gray-300 to-gray-200 shadow-gray-700/20 " : ""} `} type="button">
                  <svg
                    className="flex-shrink-0 w-6 h-6  text-dark transition duration-75 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">plans</p>
                </button>
              </li>
            }
            <li>
              <button onClick={() => navigate("/notification-list")} className={`middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg  text-dark hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize ${location.pathname === "/notification-list" ? " shadow-md hover:shadow-lg active:opacity-[0.85] bg-gradient-to-tr from-gray-300 to-gray-200 shadow-gray-700/20 " : ""} `}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-5 h-5 text-inherit">
                  <path fillRule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clipRule="evenodd" />
                </svg>
                <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">notifactions</p>
              </button>
            </li>
            {(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ??
            <li>
              <button onClick={() => navigate("/kanban")} className={` middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-dark hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize ${location.pathname === "/kanban" ? "shadow-md hover:shadow-lg active:opacity-[0.85] bg-gradient-to-tr from-gray-300 to-gray-200 shadow-gray-700/20 " : ""} `}>
                <svg
                  className="flex-shrink-0 w-6 h-6 text-shite transition duration-75 group-hover:text-gray-900"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                </svg>
                <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Kanban</p>
              </button>
            </li>
              }
              {(cookies.get("user").roles[0].name === "master" || cookies.get("user").roles[0].name === "admin") ??
            <li>
              <a>
                <button onClick={() => navigate("/user-list")} className={` middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg  text-dark hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize ${location.pathname === "/user-list" ? " shadow-md hover:shadow-lg active:opacity-[0.85] bg-gradient-to-tr from-gray-300 to-gray-200 shadow-gray-700/20 " : ""} `}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-5 h-5 text-inherit">
                    <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
                  </svg>
                  <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">users</p>

                </button>
              </a>
            </li>}
            <li>
              <a>
                <button onClick={() => clickChat()} className={` middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg  text-dark hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize ${location.pathname === "/chat" ? " shadow-md hover:shadow-lg active:opacity-[0.85] bg-gradient-to-tr from-gray-300 to-gray-200 shadow-gray-700/20 " : ""} `} type="button">
                  <svg
                    className="flex-shrink-0 w-6 h-6  text-dark transition duration-75 group-hover:text-gray-900 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
                </button>
              </a>
            </li>
        {/*     <li>
              <button onClick={() => navigate("/")}>
                <div className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg  text-dark hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize">
                  
                  <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">settings</p>
                </div>
              </button>
            </li> */}

          </ul>
        </div>
      </aside>
    </>); 
}
else
{
  return (<></>);
}

}
export default SideBar;