import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';

interface DoughnutChartProps {
  arrayLabels: string[];

  data: any;
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({ arrayLabels, data }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const canvas = chartRef.current;
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        let scaleFactor = 0;

        if (window.innerWidth < 901 && window.innerWidth > 499) {
          scaleFactor = 180;
        }
        else if (window.innerWidth < 500 && window.innerWidth > 300) {
          scaleFactor = 100;
        }
        else if (window.innerWidth < 300) {
          scaleFactor = 80;
        }
        else if (window.innerWidth < 1260 && window.innerWidth > 900) {
          scaleFactor = 200;
        }
        else if (window.innerWidth > 1260) {
          scaleFactor = 200;
        }
        else {
          scaleFactor = 300;
        }

        canvas.width = 400 + scaleFactor;
        canvas.height = 40 + scaleFactor;
        new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: arrayLabels,
            datasets: [
              {
                data: data,
                backgroundColor: ['#FF5733', '#FFC300', '#33FF57', '#338CFF'],
              },
            ],
          },
        });
      }
    }
  }, []);

  return <canvas ref={chartRef} style={{ display: ""}}/>;
};

export default DoughnutChart;
