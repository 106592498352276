
import React, { useEffect, useState } from 'react';
import AuthService from './../services/Auth';
import { useLocation,useNavigate } from 'react-router-dom';

function SettingsNav({ ...propos }) {

  const location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {

  }, []);
  return (
    <>
      {/* component */}
      {/* This is an example component */}  
      <div className={`max-w-2xl mx-auto border-2 border-xl  ${propos['show'] === 0 ? 'hidden' : 'block absolute z-20'}`} style={{ right: "10px" }}>
        <aside className="w-64 border-2 border-xl " aria-label="Sidebar">
          <div className="px-3 py-4 overflow-y-auto rounded bg-blue-50">
            <ul className="space-y-2">
              <li>Conta
                <button
                  onClick={() => navigate("/")}
                  className="flex items-center p-2 text-base font-normal text-dark rounded-lg "
                >
                  <br />
                  <span className="ml-3">charlie@exemplo.com</span>
                </button>
                <hr></hr>
              </li>
              <li>
                <button
                  onClick={() => navigate("/profile-settings")}
                  className={`flex items-center p-2 text-base font-normal text-dark rounded-lg ${ location.pathname === "/profile-settings" ? "bg-gradient-to-tr from-gray-300 to-gray-200 shadow-gray-700/20 " : "" } `}
                >
                  <svg
                    className="flex-shrink-0 w-6 h-6 text-dark transition duration-75 group-hover:text-dark "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">My Profile</span>
                </button>
              </li>
              {/* <li>
                <a
                  onClick={() => navigate("#"
                  className="flex items-center p-2 text-base font-normal text-dark rounded-lg"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-dark">
                    <path fillRule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Settings</span>
                </a>
              </li> */}
              <li>
                <button
                  onClick={() => navigate("#")}
                  className="flex items-center p-2 text-base font-normal text-dark rounded-lg"
                >
                  <div className="w-4 h-4 bg-yellow-300 rounded-full flex items-center justify-center text-white text-1xl">
                    ?
                  </div>
                  <span className="flex-1 ml-3 whitespace-nowrap">Help</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => { AuthService.logout();
                    window.location.reload();}}
                  className="flex items-center p-2 text-base font-normal text-dark rounded-lg "
                >
                  <svg
                    className="flex-shrink-0 w-6 h-6 text-dark transition duration-75 group-hover:text-dark "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </aside>

      </div>
    </>);


}
export default SettingsNav;