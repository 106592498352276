import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';

interface BarChartProps {
	arrayLabels: string[];
	labels: string;
	data: any;

}

  const BarChart: React.FC<BarChartProps> = ({arrayLabels, labels, data} ) => {
	const chartRef = useRef<HTMLCanvasElement | null>(null);


	useEffect(() => {
		
		if (chartRef.current) {
			const canvas = chartRef.current;
			const ctx = chartRef.current.getContext('2d');
			
			if (ctx) {
				let scaleFactor = 0;

				if(window.innerWidth < 901 && window.innerWidth > 499)
				{
					 scaleFactor = 200;
				}
				else if(window.innerWidth < 500 && window.innerWidth > 300) 
				{
					 scaleFactor = 800;
				}
				else if(window.innerWidth < 300) 
				{
					 scaleFactor = 2800;
				}
				else if(window.innerWidth < 1500 && window.innerWidth > 900) 
				{
					 scaleFactor = 40;
				}
				else if(window.innerWidth > 1500) 
				{
					 scaleFactor = 1;
				}
				else
				{
					 scaleFactor = 100;
				}
			
				canvas.width = 400 + scaleFactor;
				canvas.height = 40 + scaleFactor;

				new Chart(ctx, {
					type: 'bar',
					data: {
						labels: arrayLabels,
						datasets: [
							{
								label: labels,
								data: data,
								backgroundColor: 'rgba(0, 213, 58, 0.5)',
								borderColor: 'rgba(75, 192, 192, 1)',
								borderWidth: 1, 
							},
						],
					},
				});
			}
		}
	}, []);

	return (
		<>
		
			<canvas ref={chartRef} />
			

		</>);
};

export default BarChart;