import React, { useEffect, useState } from 'react';
import AuthService from '../../services/Auth';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Modal from '../../components/Modal/Modal';


function SignUp() {

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    var [validPassword, setValidPassword] = useState(0);
    var [validEmail, setValidEmail] = useState(false);
    var [passwordStrength, setPasswordStrength] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState("");
    const [data, setData] = useState({} as any);
    const [selectedCountry, setSelectedCountry] = useState('');  
    const [selectedDate, setSelectedDate] = useState('');
    let navigate = useNavigate();
    const cookies = new Cookies();
    

        useEffect(() => {
            AuthService.logout();
            
        }, []);
        const countriesList = [
            "Afeganistão", "África do Sul", "Albânia", "Alemanha", "Andorra", "Angola", "Antígua e Barbuda", "Arábia Saudita",
            "Argélia", "Argentina", "Armênia", "Austrália", "Áustria", "Azerbaijão", "Bahamas", "Bangladesh", "Barbados",
            "Barém", "Bélgica", "Belize", "Benim", "Bielorrússia", "Bolívia", "Bósnia e Herzegovina", "Botsuana", "Brasil",
            "Brunei", "Bulgária", "Burquina Faso", "Burundi", "Butão", "Cabo Verde", "Camarões", "Camboja", "Canadá",
            "Catar", "Cazaquistão", "Chade", "Chile", "China", "Chipre", "Cingapura", "Colômbia", "Comores", "Congo-Brazzaville",
            "Coreia do Norte", "Coreia do Sul", "Costa do Marfim", "Costa Rica", "Croácia", "Cuba", "Dinamarca", "Djibouti",
            "Dominica", "Egito", "El Salvador", "Emirados Árabes Unidos", "Equador", "Eritreia", "Eslováquia", "Eslovênia",
            "Espanha", "Estados Unidos", "Estônia", "Eswatini", "Etiópia", "Fiji", "Filipinas", "Finlândia", "França", "Gabão",
            "Gâmbia", "Gana", "Geórgia", "Granada", "Grécia", "Guatemala", "Guiana", "Guiné", "Guiné Equatorial", "Guiné-Bissau",
            "Haiti", "Honduras", "Hungria", "Iêmen", "Ilhas Marshall", "Ilhas Salomão", "Índia", "Indonésia", "Irã", "Iraque",
            "Irlanda", "Islândia", "Israel", "Itália", "Jamaica", "Japão", "Jordânia", "Kuwait", "Laos", "Lesoto", "Letônia",
            "Líbano", "Libéria", "Líbia", "Liechtenstein", "Lituânia", "Luxemburgo", "Macedônia do Norte", "Madagascar", "Malásia",
            "Maláui", "Maldivas", "Mali", "Malta", "Marrocos", "Maurícia", "Mauritânia", "México", "Mianmar", "Micronésia",
            "Moçambique", "Moldávia", "Mônaco", "Mongólia", "Montenegro", "Namíbia", "Nauru", "Nepal", "Nicarágua", "Níger",
            "Nigéria", "Noruega", "Nova Zelândia", "Omã", "Países Baixos", "Palau", "Panamá", "Papua-Nova Guiné", "Paquistão",
            "Paraguai", "Peru", "Polônia", "Portugal", "Quênia", "Quirguistão", "Reino Unido", "República Centro-Africana",
            "República Checa", "República Democrática do Congo", "República Dominicana", "Romênia", "Ruanda", "Rússia",
            "Salomão", "Samoa", "Santa Lúcia", "São Cristóvão e Nevis", "São Marinho", "São Tomé e Príncipe", "São Vicente e Granadinas",
            "Seicheles", "Senegal", "Serra Leoa", "Sérvia", "Singapura", "Síria", "Somália", "Sri Lanka", "Sudão", "Sudão do Sul",
            "Suécia", "Suíça", "Suriname", "Tailândia", "Taiwan", "Tajiquistão", "Tanzânia", "Timor-Leste", "Togo", "Tonga",
            "Trinidad e Tobago", "Tunísia", "Turcomenistão", "Turquia", "Tuvalu", "Ucrânia", "Uganda", "Uruguai", "Uzbequistão",
            "Vanuatu", "Vaticano", "Venezuela", "Vietnã", "Zâmbia", "Zimbábue"
        ];

          const handleDateChange = (e : any) => {
            setSelectedDate(e.target.value);
          };
          
          const handleDateEmail = (e : any) => {
            setEmail(e.target.value);

            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

            if (emailRegex.test(email)) {
                console.log("Email válido");
                setValidEmail(true);
            } else {
                console.log("Email inválido");
                setValidEmail(false);
            }
          };

          const handlePasswordChange = (e: any) => {
           
            setPassword(e.target.value)

            let strength = 0;
            if (e.target.value.length > 4)
                strength++;
            if (e.target.value.match(/[a-z]+/))  
                strength++;
            if (e.target.value.match(/[A-Z]+/)) 
                strength++;
            if (e.target.value.match(/[0-9]+/))  
                strength++;
            if (e.target.value.match(/[^a-zA-Z0-9]+/))
                strength++;
           
            if(strength > 3)
                setValidPassword(1)
            if(strength <= 3)
                setValidPassword(0)

            setPasswordStrength(strength);


        };

        const getStrengthBarColor = (index: number) => {
            if (index < passwordStrength) return 'bg-green-500';
            return 'bg-gray-200';
        };

        const register = () => {
            if(passwordStrength <= 3)
            {
               
                setShowAlert(true);
                setAlert("Heads up! Your password is like a paper fortress – easy to breach.<br /> Please choose a stronger combo for your security shield.");
            }
            else if(!validEmail)
            {
                
                setShowAlert(true);
                setAlert("Whoops! This email seems to be from another dimension.<br /> Please enter a valid email address to connect to our world.");
            }
            else
            {
                AuthService.register(nome,selectedDate,email,password,selectedCountry, null, null).then(
                    (r) => {
                      console.log(r);
                      if(cookies.get("user") !== undefined)
                      {
                          console.log(cookies.get("user"));

                          navigate('/pricing');
                      }
                      else
                      {
                        if(r.data.error)
                        {
                            var alerta = r.data.message;
                            setShowAlert(true);
                            setAlert( alerta );
                        }
                       
                      }
                        
                    }
                );
            }
         
     
        };

    return (
        <>
            <section className="flex flex-col md:flex-row h-screen items-center bg-white">
                <div className="flex w-screen md:w-full">
                    <div className="hidden sm:d-none md:flex md:w-4/4 w-4/4">
                        <div className="w-full h-screen">
                            <img
                                src="/SignUp.gif"
                                alt=""
                                className="flex h-full object-unset"
                            />

                        </div>

                    </div>
                    <div className="w-full h-screen md:w-2/5 w-3/3">
                    <nav className="text-sm sm:text-base bg-white md:p-6 mt-4 ml-2 lg:p-6 rounded-md">
                            <ol className="list-none p-0 inline-flex space-x-2">
                                <li className="flex items-center">
                                    <svg
                                        onClick={() => { window.location.href = '/'; }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 576 512"
                                        className="cursor-pointer hover:fill-green-500 transition-colors duration-300"
                                        fill="#4b5563"
                                    >
                                        {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                                        <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                                    </svg>{" "}
                                    <svg
                      className="w-6 h-6 text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                                </li>
                                <li className="flex items-center">
                                    <button
                                        onClick={() => navigate("/sign-up")}
                                        className="text-gray-600 hover:text-green-500 transition-colors duration-300"
                                    >
                                        Sign Up
                                    </button>
                                </li>
                            </ol>
                        </nav>
                        <div className="mx-auto w-full max-w-md pb-1 px-8 sm:px-0">
                            <div className="relative mt-2">
                                <div
                                    className="absolute left-0 top-2 h-0.5 w-full bg-gray-200"
                                    aria-hidden="true"
                                >
                                    <div className="absolute h-full w-1/3 bg-gray-200" />
                                    {/* change to w-2/3 for next step */}
                                    <div className="left absolute left-1/3 h-full w-1/3 bg-gradient-to-r from-gray-200" />
                                    {/* change to left-1/2 for next step */}
                                </div>
                                <ul className="relative flex w-full justify-between">
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-green-400 text-xs font-semibold text-white ring ring-green-600 ring-offset-2"
                                        >
                                            1
                                        </a>
                                    </li>
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-300 text-xs font-semibold text-white"
                                        >
                                            2
                                        </a>
                                    </li>
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-300 text-xs font-semibold text-white"
                                        >
                                            3
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="my-auto mx-auto flex flex-col justify-center px-6 md:justify-start lg:w-[28rem]">
                            <p className="text-center text-3xl font-bold md:text-left md:leading-tight mt-2">
                                Create your free account
                            </p>
                            <p className="mt-3 text-center font-medium md:text-left">
                                Already using Chat To Flow? <br />
                                <button onClick={() => navigate("/sign-in")} className="whitespace-nowrap font-semibold text-green-500 hover:text-green-700">
                                    Login here
                                </button>
                            </p>
                           
                                <div className="flex mt-2 flex-col mb-2 ">
                                <label htmlFor="with-indications" className="text-gray-700">
                                        Name
                                            <span className="text-red-500 required-dot">*</span>
                                        </label>
                                    <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-green-600">
                                        <input
                                            type="text"
                                            id="login-name"
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            placeholder="Name"
                                            value={nome}
                                            onChange={(e) => { setNome(e.target.value)}}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="relative mb-2">
                                        <label htmlFor="on-error-email" className="text-gray-700">
                                            Email
                                            <span className="text-red-500 required-dot">*</span>
                                        </label>
                                        <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-green-600">
                                        <input
                                            type="text"
                                            id="on-error-email"
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            name="email"
                                            value={email}
                                            placeholder="Your email"
                                            onChange={(e) => { handleDateEmail(e)}}
                                        />
                                        { !validEmail ? <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={15}
                                            height={15}
                                            fill="currentColor"
                                            className="absolute text-red-500 right-2 bottom-3"
                                            viewBox="0 0 1792 1792"
                                        >
                                            <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z"></path>
                                        </svg>
                                        :
                                        ''}
                                    {/*     <p className="absolute text-sm text-red-500 bottom-6">Email is invalid</p> */}
                                    </div>
                                    </div>

                                </div>
                                <div className="flex flex-col mb-2">
                                    <div className=" relative ">
                                        <label htmlFor="with-indications" className="text-gray-700">
                                            Password
                                            <span className="text-red-500 required-dot">*</span>
                                        </label>
                                        <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-green-400">
                                       <input
                                            type="password"
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            name="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => handlePasswordChange(e)}
                                        />
                                        </div>
                                        <div className="grid w-full h-1 grid-cols-12 gap-4 mt-3">
                                            <div className={` h-full col-span-3 ${getStrengthBarColor(1)} bg-gray-200 rounded `}></div>
                                            <div className={` h-full col-span-3 ${getStrengthBarColor(2)} bg-gray-200 rounded `}></div>
                                            <div className={` h-full col-span-3 ${getStrengthBarColor(3)} bg-gray-200 rounded `}></div>
                                            <div className={` h-full col-span-3 ${getStrengthBarColor(4)} bg-gray-200 rounded `}></div>
                                        </div>
                                        { validPassword ? 
                                        <>
                                            <div className="mt-2 text-green-500">Valid password</div> 
                                        </>
                                        :
                                        <>
                                        <div className="mt-2 text-red-500">Invalid password</div>
                                        </>
                                        }
                                    </div>
                                    <div className=" relative ">
                                    <label htmlFor="with-indications" className="text-gray-700">
                                        Date Of Birt
                                            <span className="text-red-500 required-dot">*</span>
                                        </label>
                                        <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-green-400">
                                        <input
                                            type="date"
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            />
                                        
                                        </div>
                                    </div>
                                    <div className=" relative ">
                                    <label htmlFor="with-indications" className="text-gray-700">
                                        Country
                                            <span className="text-red-500 required-dot">*</span>
                                        </label>
                                        <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-green-400">
                                        <select
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            value={selectedCountry}
                                            onChange={(e) => setSelectedCountry(e.target.value)}
                                            >
                                                 <option className='text-gray-400' key={0} value={0}>{"Select Country"}</option>
                                            {countriesList.map(country => (
                                                <option className='text-gray-400' key={country} value={country}>{country}</option>
                                            ))}
                                        </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="block">
                                    <input
                                        className="mr-2 h-5 w-5 appearance-none rounded border border-gray-300 bg-contain bg-no-repeat align-top text-black shadow checked:bg-green-400 focus:border-green-400 focus:shadow actived"
                                        type="checkbox"
                                        id="remember-me"
                                        style={{
                                            backgroundImage:
                                                'url("data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3e%3cpath fill="none" stroke="%23fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 10l3 3l6-6"/%3e%3c/svg%3e")'
                                        }}
                                    />
                                    <small className="inline-block" >
                                        {" "}
                                        I agree to the{" "}
                                        <button className="underline" onClick={() => navigate("/")}>
                                            Terms and Conditions
                                        </button>
                                    </small>
                                </div>
                                <button
                                    
                                    className="mt-2 rounded-lg bg-green-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 ring-offset-2 transition hover:bg-green-400 focus:ring-2 md:w-32"
                                    onClick={() => { register() } }
                                >
                                    Sign up
                                </button>
                        </div>
                    </div>
                </div>         
                <Modal
                        show={showAlert}
                        size={"small"}
                        fullscreen={false}
                        title={
                        <>
                          <div className='text-center text-dark'>
                          
                          <div dangerouslySetInnerHTML={{ __html: alert }}></div>
                          <button 
                    onClick={() => {setShowAlert(false)}} 
                    style={{ 
                        position: 'absolute', 
                        top: '10px', 
                        right: '10px', 
                        border: 'none', 
                        background: 'none' 
                    }}
                >
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="24" 
                        height="24" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="red" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
                          </div>
                        </>
                        }
                        list={
                          <>
                          <div className='text-center text-dark'>
                              
                                <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        width={75}
                                        height={75}
                                        className="text-red-500"
                                        viewBox="0 0 1792 1792"
                                    >
                                        <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z"></path>
                                </svg>
                                <span className="visually-hidden"></span>
                            
                          </div>
                        </>     
                        }
                        footer={
                          <>
                            
                          </>
                        }
                />
            </section>

        </>);


}
export default SignUp;