import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import chatProApi from '../../../services/ChatProApi';
import chatConfigApi from '../../../services/ChatConfigApi';
import Modal from '../../../components/Modal/Modal';
import KanbanBoard from '../../../components/KanbanBoard/KanbanBoard';
import PlusIcon from '../../../icons/PlusIcon';
import TrashIcon from '../../../icons/TrashIcon';
import { Picker } from 'emoji-mart';
import Cookies from 'universal-cookie';
import EmojiPicker from '../../../components/Utils/EmojiPicker';
import UserApi from '../../../services/UserApi';
import AuthService from "../../../services/Auth";

function Home() {

  let navigate = useNavigate();
  const cookies = new Cookies();
  const [showModal, setShowModal] = useState(false);
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [autoBot, setAutoBot] = useState(false);
  const [base64, setBase64] = useState("" as any);
  const [status, setStatus] = useState(false);
  const [modalConfig, setModalConfig] = useState(false);
  const [infoInstance, setInfoInstance] = useState({} as any);
  const [steps, setSteps] = useState([] as any);
  const [stepsSelected, setStepsSelected] = useState([] as any);
  var [instancia, setInstancia] = useState(0);
  var [mensagemResponse, setMensagemResponse] = useState("");
  var [mensagemsInicio, setMensagemInicio] = useState("");
  var [mensagemsFim, setMensagemFim] = useState("");
  var [mensagemErro, setMensagemErro] = useState("");
  var [imgInstancia1, setImgInstancia1] = useState("");
  const [titulos, setTitulos] = useState("");
  const [showOption, setShowOption] = useState(false);
  var [countOptions, setCountOptions] = useState(0);
  var [ShowSteps, setShowSteps] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  var [option1, setOption1] = useState("");
  var [option2, setOption2] = useState("");
  var [option3, setOption3] = useState("");
  var [option4, setOption4] = useState("");
  var [option5, setOption5] = useState("");
  var [option6, setOption6] = useState("");
  var [option7, setOption7] = useState("");
  var [option8, setOption8] = useState("");
  var [option9, setOption9] = useState("");
  var [option10, setOption10] = useState("");

  var [redirecionarSequenciaCampo1, setRedirecionarSequenciaCampo1] = useState(null);
  var [redirecionarSequenciaCampo2, setRedirecionarSequenciaCampo2] = useState(null);
  var [redirecionarSequenciaCampo3, setRedirecionarSequenciaCampo3] = useState(null);
  var [redirecionarSequenciaCampo4, setRedirecionarSequenciaCampo4] = useState(null);
  var [redirecionarSequenciaCampo5, setRedirecionarSequenciaCampo5] = useState(null);
  var [redirecionarSequenciaCampo6, setRedirecionarSequenciaCampo6] = useState(null);
  var [redirecionarSequenciaCampo7, setRedirecionarSequenciaCampo7] = useState(null);
  var [redirecionarSequenciaCampo8, setRedirecionarSequenciaCampo8] = useState(null);
  var [redirecionarSequenciaCampo9, setRedirecionarSequenciaCampo9] = useState(null);
  var [redirecionarSequenciaCampo10, setRedirecionarSequenciaCampo10] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [number, setNumber] = useState(null);
  const [editing, setEditing] = useState<'response' | 'inicio' | 'fim'>('response');

  const [Contatos, setContatos] = useState([] as any);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  var [validPassword, setValidPassword] = useState(0);
  var [validEmail, setValidEmail] = useState(false);
  var [passwordStrength, setPasswordStrength] = useState(0);

  const [ImgPerfil, setImgPerfil] = useState("");
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [SelectedContact, setSelectedContact] = useState(0);
  const [SelectedContactImage, setSelectedContactImage] = useState("");
  const [SelectedContactName, setSelectedContactName] = useState("");
  const [Conversas, setConversas] = useState({} as any);
  const textareaRef = useRef(null);

  const clickClose = () => {
    setShowModal(false);
    chatProApi.getStatus().then((status: any) => {
      if (status.data.response?.connected === true) {
        setInfoInstance(status.data.response);
        setStatus(true);
        setBase64("");
      }
    })
  }

  const wrapText = (text: string, maxLineLength: number): string => {
    let wrappedText = '';
    let currentLineLength = 0;

    for (let i = 0; i < text.length; i++) {
      if (currentLineLength >= maxLineLength && text[i] !== '\n') {
        wrappedText += '\n';
        currentLineLength = 0;
      }
      wrappedText += text[i];
      currentLineLength++;

      if (text[i] === '\n') {
        currentLineLength = 0;
      }
    }

    return wrappedText;
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    const wrappedText = wrapText(originalText, 32);
    setMensagemResponse(wrappedText);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (editing === 'response') {
        setMensagemResponse(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      } else if (editing === 'inicio') {
        setMensagemInicio(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      } else if (editing === 'fim') {
        setMensagemFim(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      }
    }
  };

  const handleChangeInicio = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    const wrappedText = wrapText(originalText, 32);
    setMensagemInicio(wrappedText);

  }

  const handleChangeFim = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    const wrappedText = wrapText(originalText, 32);
    setMensagemFim(wrappedText);

  }

  const renderConversation = (conversation: any) => {
    return conversation.split('\n\n').map((text: any, index: any, array: any) => (
      <React.Fragment key={index}>
        <p className="text-sm mt-1">{text}</p>
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  }

  const handleAutoBot = (id: number) => {

    UserApi.updateUserInstanceActive(id).then((user: any) => {
      cookies.set('user', user.data);
      window.location.reload();
    })
  }

  const handleRemoveOption = () => {

    if (countOptions > 1) {
      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      if (countOptions - 1 === 0) {
        setShowOption(false);
        setOption1("");
        setOption1("");
        setRedirecionarSequenciaCampo1(null);
      }
      else if (countOptions - 1 === 1) {
        setOption2("");
        setRedirecionarSequenciaCampo2(null);
      }
      else if (countOptions - 1 === 2) {
        setOption3("");
        setRedirecionarSequenciaCampo3(null);
      }
      else if (countOptions - 1 === 3) {
        setOption4("");
        setRedirecionarSequenciaCampo4(null);
      }
      else if (countOptions - 1 === 4) {
        setOption5("");
        setRedirecionarSequenciaCampo5(null);
      }
      else if (countOptions - 1 === 5) {
        setOption6("");
        setRedirecionarSequenciaCampo6(null);
      }
      else if (countOptions - 1 === 6) {
        setOption7("");
        setRedirecionarSequenciaCampo7(null);
      }
      else if (countOptions - 1 === 7) {
        setOption8("");
        setRedirecionarSequenciaCampo8(null);
      }
      else if (countOptions - 1 === 8) {
        setOption9("");
        setRedirecionarSequenciaCampo9(null);
      }
      else if (countOptions - 1 === 9) {
        setOption10("");
        setRedirecionarSequenciaCampo10(null);
      }
      setCountOptions(countOptions - 1);
    }

  }

  const handleRemoveStep = (data: any) => {
    console.log(data);
    chatConfigApi.deleteBotConfig(data.id).then((r) => {
      chatConfigApi.listBotConfigs({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        setSteps(response);
        handleClickSelectedStep([]);
      });
    })

  }
  const handleCountOption = () => {

    if (countOptions < 10) {
      if (countOptions === 1) {
        if (option1 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 2) {
        if (option1 !== "" && option2 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 3) {
        if (option1 !== "" && option2 !== "" && option3 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 4) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 5) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 6) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 7) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 8) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "" && option8 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 9) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "" && option8 !== "" && option9 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 10) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "" && option8 !== "" && option9 !== "" && option10 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
    }
  }

  const formatLastMessageTime = (unixTimestamp: any) => {
    const date = new Date(unixTimestamp * 1000); // Convertendo de segundos para milissegundos
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    return date.toLocaleTimeString('pt-BR', options);
  }

  const handleCheckBox = () => {
    if (showOption === true) {
      setShowOption(false);
      setCountOptions(0);
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setOption5("");
      setOption6("");
      setOption7("");
      setOption8("");
      setOption9("");
      setOption10("");
      setRedirecionarSequenciaCampo1(null);
      setRedirecionarSequenciaCampo2(null);
      setRedirecionarSequenciaCampo3(null);
      setRedirecionarSequenciaCampo4(null);
      setRedirecionarSequenciaCampo5(null);
      setRedirecionarSequenciaCampo6(null);
      setRedirecionarSequenciaCampo7(null);
      setRedirecionarSequenciaCampo8(null);
      setRedirecionarSequenciaCampo9(null);
      setRedirecionarSequenciaCampo10(null);
    }
    else {
      setShowOption(true);
      setCountOptions(1);
    }
  }

  const getStrengthBarColor = (index: number) => {
    if (index < passwordStrength) return 'bg-green-500';
    return 'bg-gray-200';
  };

  const handlePasswordChange = (e: any) => {

    setPassword(e.target.value)

    let strength = 0;
    if (e.target.value.length > 4)
      strength++;
    if (e.target.value.match(/[a-z]+/))
      strength++;
    if (e.target.value.match(/[A-Z]+/))
      strength++;
    if (e.target.value.match(/[0-9]+/))
      strength++;
    if (e.target.value.match(/[^a-zA-Z0-9]+/))
      strength++;

    if (strength > 3) {
      setValidPassword(1)
    }
    else if (strength <= 3)
      setValidPassword(0)
    else
      setValidPassword(0)

    setPasswordStrength(strength);


  };

  const handleDisconnect = () => {
    setShowDisconnect(false);
    setStatus(false);
    chatProApi.removeSession().then((response: any) => {
      window.location.reload();
    })


  };

  useEffect(() => {
    if (base64 === "") {
      chatProApi.getStatus().then((status: any) => {
        if (status.data.response !== undefined) {

          if (status.data.response.code === 400) {

            chatProApi.QrCodeChatPro().then((qr) => {

              if (qr.data.response['qr'] === "") {
                chatProApi.reload().then((response) => {
                  chatProApi.QrCodeChatPro().then((qr) => {
                    if (qr.data.response['qr'] !== "") {
                      setBase64(qr.data.response['qr']);
                    }
                  })
                })
              }
              else {
                setBase64(qr.data.response['qr']);
              }
            })
          }
          else if (status.data.response?.connected === true) {
            setInfoInstance(status.data.response);
            chatProApi.getProfile({ 'number': status.data.response.info.Wid.split('@')[0] }).then((r) => {
              setImgInstancia1(r?.data.response['imgUrl']);
            })
            setStatus(true);
            setBase64("");
          }
        }
        if (status.data.response?.connected) {
          var phone = status.data.response.info['Wid'].split('@');
          chatProApi.getProfile({ 'number': phone[0] }).then((r) => {
            setImgPerfil(r?.data.response['imgUrl']);
            chatProApi.listChats().then((contatos: any) => {
              setContatos(contatos.data.response);
              setSelectedContact(contatos.data.response[0]['jid'].split('@')[0]);
              chatProApi.getProfile({ 'number': contatos.data.response[0]['jid'].split('@')[0] }).then((r: any) => {
                setNumber(status.data.response.info.Wid.split('@')[0]);
                setSelectedContactName(r.data.response?.name);
                setSelectedContactImage(r.data.response?.imgUrl);
              })

              if (cookies.get('user').roles[0].name === 'atendente') {
                console.log('atendente');
                UserApi.showUser(cookies.get('user').instances_cliente[0].codUserCliente).then((user: any) => {
                  setConversas(user.data.user_instance[0].user_instance_web_hook);
                })
              }
              else {
                UserApi.showUser(cookies.get('user').id).then((user: any) => {

                  setConversas(user.data.user_instance[0].user_instance_web_hook);
                })
              }

            })
          })

        }
      });

    }

    chatConfigApi.listBotConfigs({
      "filters": {
        "codUserInstance": instancia
      }
    }).then((response) => {
      setSteps(response);
    });

    const intervalo = setInterval(() => {
      if (base64 === "") {
        chatProApi.getStatus().then((status: any) => {

          if (status.data.response !== undefined) {

            if (status.data.response?.connected === true) {
              setInfoInstance(status.data.response);
              setStatus(true);
              setBase64("");
            }
            else {
              chatProApi.QrCodeChatPro().then((qr) => {

                if (qr.data.response['qr'] === "") {
                  chatProApi.reload().then((response) => {
                    chatProApi.QrCodeChatPro().then((qr) => {
                      if (qr.data.response['qr'] !== "") {
                        setBase64(qr.data.response['qr']);
                      }
                    })
                  })
                }
                else {
                  setBase64(qr.data.response['qr']);
                }
              })
            }
          }
          else {
            navigate("sign-in");
          }
        })
      }
      chatProApi.getStatus().then((status: any) => {
        if (status.data.response?.connected === true) {

          if (status.data.response?.connected) {
            var phone = status.data.response.info['Wid'].split('@');
            chatProApi.getProfile({ 'number': phone[0] }).then((r) => {
              setImgPerfil(r?.data.response['imgUrl']);
              chatProApi.listChats().then((contatos: any) => {
                setContatos(contatos.data.response);
                setSelectedContact(contatos.data.response[0]['jid'].split('@')[0]);
                chatProApi.getProfile({ 'number': contatos.data.response[0]['jid'].split('@')[0] }).then((r: any) => {
                  setNumber(status.data.response.info.Wid.split('@')[0]);
                  setSelectedContactName(r.data.response.name);
                  setSelectedContactImage(r.data.response?.imgUrl);
                })

                if (cookies.get('user').roles[0].name === 'atendente') {
                  console.log('atendente');
                  UserApi.showUser(cookies.get('user').instances_cliente[0].codUserCliente).then((user: any) => {
                    setConversas(user.data.user_instance[0].user_instance_web_hook);
                  })
                }
                else {
                  UserApi.showUser(cookies.get('user').id).then((user: any) => {

                    setConversas(user.data.user_instance[0].user_instance_web_hook);
                  })
                }

              })
            })

          }
        }
      })
    }, 90 * 1000);

    return () => clearInterval(intervalo);
  }, []);

  const handleDateChange = (e: any) => {
    setSelectedDate(e.target.value);
  };

  const onEmojiSelectInicio = (emoji: any) => {
    setMensagemInicio(mensagemsInicio + emoji);
  };

  const onEmojiSelectResponse = (emoji: any) => {
    setMensagemResponse(mensagemResponse + emoji);
  };
  const onEmojiSelectFim = (emoji: any) => {
    setMensagemFim(mensagemsFim + emoji);
  };
  const onEmojiSelectErro = (emoji: any) => {
    setMensagemErro(mensagemErro + emoji);
  };


  const handleClickOption1 = (data: any) => {
    console.log(data);
    setOption1(data.titulo);
    setRedirecionarSequenciaCampo1(data.id);
  }

  const handleClickOption2 = (data: any) => {
    console.log(data);
    setOption2(data.titulo);
    setRedirecionarSequenciaCampo2(data.id);
  }

  const handleClickOption3 = (data: any) => {
    console.log(data);
    setOption3(data.titulo);
    setRedirecionarSequenciaCampo3(data.id);
  }

  const handleClickOption4 = (data: any) => {
    console.log(data);
    setOption4(data.titulo);
    setRedirecionarSequenciaCampo4(data.id);
  }

  const handleClickOption5 = (data: any) => {
    console.log(data);
    setOption5(data.titulo);
    setRedirecionarSequenciaCampo5(data.id);
  }


  const handleDateEmail = (e: any) => {
    setEmail(e.target.value);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (emailRegex.test(email)) {
      console.log("Email válido");
      setValidEmail(true);
    } else {
      console.log("Email inválido");
      setValidEmail(false);
    }
  };

  const handleClickOption6 = (data: any) => {
    console.log(data);
    setOption6(data.titulo);
    setRedirecionarSequenciaCampo6(data.id);
  }

  const handleClickOption7 = (data: any) => {
    console.log(data);
    setOption7(data.titulo);
    setRedirecionarSequenciaCampo7(data.id);
  }

  const handleClickOption8 = (data: any) => {
    console.log(data);
    setOption8(data.titulo);
    setRedirecionarSequenciaCampo8(data.id);
  }

  const register = () => {
    if (passwordStrength <= 3) {

      setShowAlert(true);
      setAlert("Heads up! Your password is like a paper fortress – easy to breach.<br /> Please choose a stronger combo for your security shield.");
    }
    else if (!validEmail) {

      setShowAlert(true);
      setAlert("Whoops! This email seems to be from another dimension.<br /> Please enter a valid email address to connect to our world.");
    }
    else {
      setShowAlert(true);
      setAlert("User created.");
      AuthService.registerAtendente(nome, selectedDate, email, password, selectedCountry, null, null).then(
        (r) => {
          setShowModalAddUser(false);
          setNome('');
          setEmail('');
          setPassword('');
        }
      );
    }


  };

  const handleClickOption9 = (data: any) => {
    console.log(data);
    setOption9(data.titulo);
    setRedirecionarSequenciaCampo9(data.id);
  }

  const handleClickOption10 = (data: any) => {
    console.log(data);
    setOption10(data.titulo);
    setRedirecionarSequenciaCampo10(data.id);
  }

  const handleClickSelectedStep = (data: any) => {
    if (data.length === 0) {
      setMensagemInicio("");
      setMensagemFim("");
      setMensagemErro("");
      setTitulos("");
      setShowOption(false);
      setCountOptions(0);
      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setOption5("");
      setOption6("");
      setOption7("");
      setOption8("");
      setOption9("");
      setOption10("");
      setRedirecionarSequenciaCampo1(null);
      setRedirecionarSequenciaCampo2(null);
      setRedirecionarSequenciaCampo3(null);
      setRedirecionarSequenciaCampo4(null);
      setRedirecionarSequenciaCampo5(null);
      setRedirecionarSequenciaCampo6(null);
      setRedirecionarSequenciaCampo7(null);
      setRedirecionarSequenciaCampo8(null);
      setRedirecionarSequenciaCampo9(null);
      setRedirecionarSequenciaCampo10(null);

    }
    else {
      setMensagemInicio("");
      setMensagemFim("");
      setMensagemErro("");
      setTitulos("");
      setShowOption(false);
      setCountOptions(0);
      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setOption5("");
      setOption6("");
      setOption7("");
      setOption8("");
      setOption9("");
      setOption10("");
      setRedirecionarSequenciaCampo1(null);
      setRedirecionarSequenciaCampo2(null);
      setRedirecionarSequenciaCampo3(null);
      setRedirecionarSequenciaCampo4(null);
      setRedirecionarSequenciaCampo5(null);
      setRedirecionarSequenciaCampo6(null);
      setRedirecionarSequenciaCampo7(null);
      setRedirecionarSequenciaCampo8(null);
      setRedirecionarSequenciaCampo9(null);
      setRedirecionarSequenciaCampo10(null);

      setMensagemInicio(data.mensagemInicio === null ? "" : data.mensagemInicio);
      setMensagemFim(data.mensagemFim === null ? "" : data.mensagemFim);
      setMensagemErro(data.mensagemErro === null ? "" : data.mensagemErro);
      setShowOption(data.opcao === 0 ? false : true)
      setTitulos(data.titulo);
      setCountOptions(0);
      var count = 0;
      if (data.campo1 !== null) {
        setOption1(data.campo1);
        count++;
        setRedirecionarSequenciaCampo1(data.redirecionarSequenciaCampo1);
      }
      else {
        setOption1("");
        setRedirecionarSequenciaCampo1(null);
      }
      if (data.campo2 !== null) {
        setOption2(data.campo2);
        count++;
        setRedirecionarSequenciaCampo2(data.redirecionarSequenciaCampo2);
      }
      else {
        setOption2("");
        setRedirecionarSequenciaCampo2(null);
      }
      if (data.campo3 !== null) {
        setOption3(data.campo3);
        count++;
        setRedirecionarSequenciaCampo3(data.redirecionarSequenciaCampo3);
      }
      else {
        setOption3("");
        setRedirecionarSequenciaCampo3(null);
      }
      if (data.campo4 !== null) {
        setOption4(data.campo4);
        count++;
        setRedirecionarSequenciaCampo4(data.redirecionarSequenciaCampo4);
      }
      else {
        setOption4("");
        setRedirecionarSequenciaCampo4(null);
      }
      if (data.campo5 !== null) {
        setOption5(data.campo5);
        count++;
        setRedirecionarSequenciaCampo5(data.redirecionarSequenciaCampo5);
      }
      else {
        setOption5("");
        setRedirecionarSequenciaCampo5(null);
      }
      if (data.campo6 !== null) {
        setOption6(data.campo6);
        count++;
        setRedirecionarSequenciaCampo6(data.redirecionarSequenciaCampo6);
      }
      else {
        setOption6("");
        setRedirecionarSequenciaCampo6(null);
      }
      if (data.campo7 !== null) {
        setOption7(data.campo7);
        count++;
        setRedirecionarSequenciaCampo7(data.redirecionarSequenciaCampo7);
      }
      else {
        setOption7("");
        setRedirecionarSequenciaCampo7(null);
      }
      if (data.campo8 !== null) {
        setOption8(data.campo8);
        count++;
        setRedirecionarSequenciaCampo8(data.redirecionarSequenciaCampo8);
      }
      else {
        setOption8("");
        setRedirecionarSequenciaCampo8(null);
      }
      if (data.campo9 !== null) {
        setOption9(data.campo9);
        count++;
        setRedirecionarSequenciaCampo9(data.redirecionarSequenciaCampo9);
      }
      else {
        setOption9("");
        setRedirecionarSequenciaCampo9(null);
      }
      if (data.campo10 !== null) {
        setOption1(data.campo10);
        count++;
        setRedirecionarSequenciaCampo10(data.redirecionarSequenciaCampo10);
      }
      else {
        setOption10("");
        setRedirecionarSequenciaCampo10(null);
      }

      setCountOptions(count);
    }

    setStepsSelected(data);
  }

  const handleSelectNumber = (number: any) => {
    chatProApi.getProfile({ 'number': number['jid'].split('@')[0] }).then((r: any) => {
      setNumber(number['jid'].split('@')[0]);
      console.log(r.data.response)
      setSelectedContactName(r.data.response.name);
      setSelectedContactImage(r.data.response?.imgUrl);
    })
    setSelectedContact(number['jid'].split('@')[0]);
    setShowChat(true);
    const bottomScroll = document.getElementById("scrollableDiv");
    if (bottomScroll) {
      bottomScroll.scrollTop = bottomScroll.scrollHeight;
    }

  }

  const handleSave = () => {

    console.log(countOptions);

    if (stepsSelected.length !== 0) {
      const updateConfigData = {
        titulo: titulos,
        codUserInstance: instancia,
        enviarDocumento: false,
        opcao: showOption,
        campo1: option1,
        campo2: option2,
        campo3: option3,
        campo4: option4,
        campo5: option5,
        campo6: option6,
        campo7: option7,
        campo8: option8,
        campo9: option9,
        campo10: option10,
        redirecionarSequenciaCampo1: redirecionarSequenciaCampo1,
        redirecionarSequenciaCampo2: redirecionarSequenciaCampo2,
        redirecionarSequenciaCampo3: redirecionarSequenciaCampo3,
        redirecionarSequenciaCampo4: redirecionarSequenciaCampo4,
        redirecionarSequenciaCampo5: redirecionarSequenciaCampo5,
        redirecionarSequenciaCampo6: redirecionarSequenciaCampo6,
        redirecionarSequenciaCampo7: redirecionarSequenciaCampo7,
        redirecionarSequenciaCampo8: redirecionarSequenciaCampo8,
        redirecionarSequenciaCampo9: redirecionarSequenciaCampo9,
        redirecionarSequenciaCampo10: redirecionarSequenciaCampo10,
        mensagemInicio: mensagemsInicio,
        mensagemFim: mensagemsFim,
        mensagemErro: mensagemErro
      };


      chatConfigApi.updateBotConfig(stepsSelected.id, updateConfigData).then((r) => {
        chatConfigApi.listBotConfigs({
          "filters": {
            "codUserInstance": instancia
          }
        }).then((response) => {
          setSteps(response);
        });
      });
    }
    else {
      const botConfigData = {
        titulo: titulos,
        codUserInstance: instancia,
        enviarDocumento: false,
        opcao: showOption,
        campo1: option1,
        campo2: option2,
        campo3: option3,
        campo4: option4,
        campo5: option5,
        campo6: option6,
        campo7: option7,
        campo8: option8,
        campo9: option9,
        campo10: option10,
        redirecionarSequenciaCampo1: redirecionarSequenciaCampo1,
        redirecionarSequenciaCampo2: redirecionarSequenciaCampo2,
        redirecionarSequenciaCampo3: redirecionarSequenciaCampo3,
        redirecionarSequenciaCampo4: redirecionarSequenciaCampo4,
        redirecionarSequenciaCampo5: redirecionarSequenciaCampo5,
        redirecionarSequenciaCampo6: redirecionarSequenciaCampo6,
        redirecionarSequenciaCampo7: redirecionarSequenciaCampo7,
        redirecionarSequenciaCampo8: redirecionarSequenciaCampo8,
        redirecionarSequenciaCampo9: redirecionarSequenciaCampo9,
        redirecionarSequenciaCampo10: redirecionarSequenciaCampo10,
        mensagemInicio: mensagemsInicio,
        mensagemFim: mensagemsFim,
        mensagemErro: mensagemErro,
      };

      chatConfigApi.createBotConfig(botConfigData).then((r) => {
        chatConfigApi.listBotConfigs({
          "filters": {
            "codUserInstance": instancia
          }
        }).then((response) => {
          setSteps(response);
        });
      });
    }


  };

  return (
    <>
      <div className="w-screen h-auto ">
        <div className="h-8 xml:h-28 bg-green-500" />

        <div className="flex flex-col bg-gray-50 sm:flex-row border border-grey rounded shadow-lg mb-10 m-4 h-auto">

          <div className="w-full bg-gray-50 border-md sm:border-md flex flex-col">

            <div className="py-2 bg-gray-50 px-4 bg-grey-lighter flex flex-col w-full">


              <div className="flex flex-wrap md:m-10">

                <div className="w-full p-4 border-b border-r border-l border-t sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/3">
                  <div className="p-4 max-w bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700 h-full">
                    {cookies.get('user') !== undefined &&
                      cookies.get('user').roles[0].name !== 'atendente' ?
                      <>
                        <div className="flex items-center mb-6">

                          <svg
                            width={20}
                            height={20}
                            className="w-6 h-6 text-green-500"
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
                          </svg>

                          <div className="ml-4 text-xl">My Plan</div>
                        </div>

                        <div className="flex whitespace-nowrap items-center justify-between mb-4 space-x-12">
                          <span className="flex items-center px-1 py-1 text-xs font-semibold text-gray-500 bg-gray-200 rounded-md">
                            BASIC PLAN
                          </span>
                          <span className="flex items-center px-1 py-1 text-xs font-semibold text-red-400 bg-white border border-red-400 rounded-md">RENEW NOW.</span>
                        </div>
                        <span className="flex whitespace-nowrap items-center px-1 py-1 mt-4 text-xs font-semibold text-yellow-500 bg-yellow-50">
                          DUE DATE : 18 JUN 2024
                        </span>
                      </>
                      :
                      <></>
                    }
                    {cookies.get('user') !== undefined && cookies.get('user').roles[0].name !== 'atendente' ? <>
                      <div className="block m-auto">
                        <div>
                          <span className="inline-block text-sm text-gray-500 dark:text-gray-100">
                            Users :
                            <span className="font-bold text-gray-700 dark:text-white">{cookies.get('atendentes').length}</span>
                            /5
                          </span>
                        </div>
                        <div className="w-full h-2 mt-2 bg-gray-200 rounded-full">
                          <div className="w-1/1 h-full text-xs text-center text-white bg-green-500 rounded-full"></div>
                        </div>
                      </div>
                      <div className="flex items-center justify-start my-4 space-x-4">
                        {cookies.get('user').roles[0].name === 'cliente' &&
                          <>
                            <button onClick={() => { showModalAddUser ? setShowModalAddUser(false) : setShowModalAddUser(true) }} className="flex items-center px-2 py-1 text-xs font-semibold text-gray-500 hover:bg-gray-100 rounded-md bg-green-50">
                              ADD USER
                            </button>
                            <button disabled className="flex items-center px-2 py-1 text-xs font-semibold text-gray-500 bg-green-50 hover:bg-gray-100 rounded-md">
                              PLAN UPGRADE
                            </button>
                          </>

                        }

                      </div>
                      {cookies.get('user') !== undefined &&
                        cookies.get('user').roles[0]?.name !== 'atendente' &&
                        <>

                        </>

                      }
                      <div className="flow-root">
                        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                          {cookies.get('user') !== undefined &&
                            cookies.get('user').roles[0]?.name !== 'atendente' ?

                            cookies.get('atendentes').map((r: any, key: number) => {
                              return (<>
                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <img className="inline-block h-10 w-10 rounded-full object-cover ring-2 ring-white" src="./Anoni.jpg" />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        {r.user.nome}
                                      </p>
                                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {r.user.email}
                                      </p>
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-green-gray-500">
                                        <path fillRule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                                      </svg>

                                    </div>
                                  </div>
                                </li>
                              </>)
                            })

                            : <></>
                          }
                        </ul>
                      </div>
                    </> :
                      <>
                        <div className="flex items-center mb-6">

                          <svg
                            width={20}
                            height={20}
                            className="w-6 h-6 text-green-500"
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
                          </svg>

                          <div className="ml-4 text-xl">Awaiting Service</div>
                        </div>
                        <div className="flow-root">
                          <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                            <li className="py-3 sm:py-4">
                              <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                  <img
                                    className="h-12 w-12 rounded-full"
                                    src={/* r.imgUrl !== undefined ? r.imgUrl :  */"./Anoni.jpg"}
                                    alt="Avatar"
                                  />
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {/*  {r.name} */} nome
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {/* {r.unread} */} unanswered messages.
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    Last Message <br /> {/* {formatLastMessageTime(r.lastMessageTime)} */}
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                  <svg
                                    className="w-6 h-6"
                                    onClick={() => { /* handleSelectNumber(r); */ }}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                                    />
                                  </svg>

                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </>}
                  </div>


                </div>
                <div className="w-full p-4 border-b border-r border-t sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/3">
                  <div className="p-4 max-w bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700 h-full">
                    <div className="flex items-center mb-6">
                      <svg
                        width={15}
                        height={15}
                        className="ml-1"
                        fill="currentColor"
                        viewBox="0 0 384 512"
                      >
                        <path
                          d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8c-.6 16.1-4.2 28.5-11 36.9c-15.4 19.2-49.3 22.4-85.2 25.7c-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3c0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6c3.1-5.2 7.8-9.8 14.9-13.4c16.2-8.2 40.4-10.4 66.1-12.8c42.2-3.9 90-8.4 118.2-43.4c14-17.4 21.1-39.8 21.6-67.9c31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16s-16-7.2-16-16s7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16s16 7.2 16 16s-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16s-16-7.2-16-16s7.2-16 16-16z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <div className="ml-4 text-xl">Instances</div>
                    </div>

                    <ul>
                      {cookies.get('user') !== undefined &&
                        cookies.get('user').roles[0]?.name !== 'atendente' &&
                        <li className="flex items-center justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                          <button onClick={() => {
                            setShowModal(true);
                            chatProApi.QrCodeChatPro().then((qr) => {
                              setBase64(qr.data.response['qr']);
                            })
                          }} className="btn text-center align-center hover:bg-green-100 bg-green-50 rounded-md p-2">
                            <div className="flex items-center justify-start text-sm"><svg width="25" height="15" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="text-gray-500">

                              <line x1="5" y1="25" x2="45" y2="25" stroke="green" />
                              <line x1="25" y1="5" x2="25" y2="45" stroke="green" />
                            </svg>
                              <span className='row'> Connect new instance </span>
                            </div></button>
                        </li>
                      }
                      {!status ?
                        <li className="flex items-center justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                          <div className="flex items-center justify-start text-sm">
                            <span className="mx-4">1</span>
                            <span>You don't have a connection yet.</span>
                          </div>
                        </li>
                        :
                        <>
                          {cookies.get('user') !== undefined ?
                            cookies.get('user').roles[0]?.name !== 'atendente' ?
                              cookies.get('user').user_instance.map((r: any, key: number) => {
                                { console.log(cookies.get('user').atendentes) }

                                return (
                                  <>
                                    <li className="col-12 flex justify-between items-center py-2 text-gray-600 border-b border-gray-100 dark:text-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-200">

                                      <div className='row'>
                                        <div className="d-flex items-center mx-2">
                                          {/* <span className="mx-4">{key + 1} </span> */}
                                          <p className='text-xsm'>{key + 1} - {infoInstance.info.Pushname}</p>
                                          <div className='col-12'>
                                            <div className="relative inline-block w-10 items-end mr-4 select-none">

                                              {r.ativo === 1 ?
                                                <>
                                                  <small className='text-center whitespace-nowrap'>Automation</small>
                                                  <input
                                                    type="checkbox"
                                                    name="toggle"
                                                    id="Green"
                                                    title="Desactive / Active Bot"
                                                    checked
                                                    onClick={() => { setAutoBot(false); handleAutoBot(r.id); }}
                                                    className={`checked:bg-green-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                                  />
                                                  <label
                                                    htmlFor="Green"
                                                    className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                                  ></label>
                                                </>
                                                :
                                                <>
                                                  <small className='text-center whitespace-nowrap'>Automation</small>
                                                  <input
                                                    type="checkbox"
                                                    name="toggle"
                                                    title="Desactive / Active Bot"
                                                    id="Green"
                                                    onClick={() => { setAutoBot(true); handleAutoBot(r.id); }}
                                                    className={`checked:bg-green-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                                  />
                                                  <label
                                                    htmlFor="Green"
                                                    className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                                  ></label>
                                                </>
                                              }

                                            </div>
                                            <div className="relative inline-block ml-6 col-6 select-none">
                                              <small className='text-center whitespace-nowrap'>Settings</small>
                                              <div className="row">
                                                <button className="m-1" onClick={() => { setModalConfig(true); setInstancia(r.id); }} >
                                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-green-gray-500">
                                                    <path fillRule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>
                                            <div className="relative inline-block ml-3 col-6 select-none">
                                              <small className='text-center'>Disconect</small>
                                              <div className="row">
                                                <button className="m-1" onClick={() => { setShowDisconnect(true); setInstancia(r.id); }} >
                                                  <svg className="h-5 w-5 text-red-400 " width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="currentColor" d="M21.7803 3.28033C22.0732 2.98744 22.0732 2.51256 21.7803 2.21967C21.4874 1.92678 21.0126 1.92678 20.7197 2.21967L18.7077 4.23161C17.0483 3.05263 14.7323 3.20693 13.2448 4.6945L12.1767 5.76252C11.4933 6.44594 11.4933 7.55398 12.1767 8.2374L15.7625 11.8232C16.446 12.5066 17.554 12.5066 18.2374 11.8232L19.3054 10.7552C20.793 9.26761 20.9473 6.9517 19.7684 5.29228L21.7803 3.28033ZM18.1945 5.75516L18.2173 5.77798L18.2197 5.78033L18.222 5.78267L18.2448 5.80542C19.3187 6.87936 19.3187 8.62056 18.2448 9.6945L17.1767 10.7625C17.0791 10.8602 16.9208 10.8602 16.8232 10.7625L13.2374 7.17674C13.1398 7.07911 13.1398 6.92082 13.2374 6.82318L14.3054 5.75516C15.3794 4.68122 17.1206 4.68122 18.1945 5.75516Z" />
                                                    <path fill="currentColor" d="M10.7803 11.2803C11.0732 10.9874 11.0732 10.5126 10.7803 10.2197C10.4874 9.92678 10.0126 9.92678 9.71967 10.2197L8.00001 11.9393L7.53035 11.4697C7.23746 11.1768 6.76258 11.1768 6.46969 11.4697L4.69456 13.2448C3.20701 14.7324 3.0527 17.0483 4.23163 18.7077L2.21967 20.7197C1.92678 21.0126 1.92678 21.4874 2.21967 21.7803C2.51256 22.0732 2.98744 22.0732 3.28033 21.7803L5.29229 19.7684C6.95171 20.9473 9.26766 20.793 10.7552 19.3055L12.5303 17.5303C12.8232 17.2374 12.8232 16.7626 12.5303 16.4697L12.0607 16L13.7803 14.2803C14.0732 13.9874 14.0732 13.5126 13.7803 13.2197C13.4874 12.9268 13.0126 12.9268 12.7197 13.2197L11 14.9393L9.06067 13L10.7803 11.2803ZM7.46631 13.527L7.46967 13.5303L7.47305 13.5337L10.4664 16.527L10.4697 16.5303L10.473 16.5336L10.9394 17L9.69456 18.2448C8.62062 19.3187 6.87942 19.3187 5.80548 18.2448L5.75522 18.1945C4.68128 17.1206 4.68128 15.3794 5.75522 14.3055L7.00002 13.0607L7.46631 13.527Z" />
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              })
                              :

                              cookies.get('user').instances_cliente.map((r: any, key: number) => {

                                return (
                                  <>
                                    <li className="flex justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                                      <div className="flex items-end text-sm mx-4">
                                        <span className="mx-4">{key + 1} -</span>
                                        <span>{infoInstance.info.Pushname}</span>

                                      </div>
                                      <button onClick={() => { setModalConfig(true); setInstancia(r.id); }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-green-gray-500">
                                          <path fillRule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                                        </svg>
                                      </button>
                                    </li>
                                  </>
                                );
                              })
                            :
                            <></>
                          }
                          <li className="flex justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                            <div className="flex items-center justify-start text-sm">
                              <span className="mx-4"></span>
                              <span>Update the plan and add new phones.</span>

                            </div>

                          </li>
                        </>
                      }
                    </ul>


                    <div className="flex -space-x-2 mt-6">

                      <img
                        className="inline-block h-10 w-10 rounded-full object-cover ring-2 ring-white"
                        src={imgInstancia1 !== "" ? imgInstancia1 : "./Anoni.jpg"}
                      />
                      {/*    <button onClick={() => navigate("/")} className="">
                        <img
                          className="inline-block h-10 w-10 rounded-full object-cover ring-2 ring-white"
                          src="/perfil.jpg"
                          alt="Max"
                        />
                      </button> */}

                    </div>

                  </div>

                </div>

                <div className="w-full p-4 border-b border-r border-t sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/3">

                  <div className="p-4 max-w bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700 h-full">
                    <div className="flex items-center mb-6">
                      <svg
                        width={20}
                        height={20}
                        className="w-6 h-6 text-green-500"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
                      </svg>
                      <div className="ml-4 text-xl">Latest conversations</div>
                    </div>
                    <div className="flow-root">
                      <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                        {Contatos.length === 0 ?
                          <li className="py-3 sm:py-4">no conversation recorded</li>
                          :
                          Contatos.map((r: any) => {
                            console.log(r);
                            return (
                              <>
                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <img
                                        className="h-12 w-12 rounded-full"
                                        src={r.imgUrl !== undefined ? r.imgUrl : "./Anoni.jpg"}
                                        alt="Avatar"
                                      />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        {r.name}
                                      </p>
                                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {r.unread} unanswered messages.
                                      </p>
                                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Last Message <br /> {formatLastMessageTime(r.lastMessageTime)}
                                      </p>
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                      <svg
                                        className="w-6 h-6"
                                        onClick={() => { handleSelectNumber(r); }}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                                        />
                                      </svg>

                                    </div>
                                  </div>
                                </li>

                              </>);
                          })}

                      </ul>
                    </div>
                  </div>
                </div>
                <div className="w-full p-4 border-b border-r border-l border-t xml:w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-full">
                  <div className="p-4 max-w bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700 h-full">

                  </div>


                </div>
                <div className="w-full p-4 w-1/1 sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-full border-b border-r border-l border-t">
                  <div className="p-4 max-w bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700 h-full">

                  </div>


                </div>
              </div>


            </div>

          </div>
        </div>

      </div>

      <Modal
        show={showModalAddUser}
        size={"small"}
        fullscreen={true}
        title={
          <>
            <div className='text-center text-dark'>

              <button
                onClick={() => { setShowModalAddUser(false); }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  border: 'none',
                  background: 'none'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </>
        }
        list={
          <>

            <div className='flex w-screen flex-col items-center text-center text-dark rounded-xl border'>
              <div className="flex flex-col justify-center px-4 w-full">
                <h1 className="text-gray-800 font-bold text-xl mb-1">Create new account for one customer Service representative here.</h1>
                <div className="flex justify-center py-2 items-center">

                  <div className="w-full mt-4 ">
                    <div className="flex items-center py-2 px-3 mb-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <input
                        type="text"
                        id="login-name"
                        className="w-full ml-2 appearance-none border-green-300 h-8 pl-2 border rounded text-base text-gray-700 placeholder-gray-400"
                        placeholder="Name"
                        value={nome}
                        onChange={(e) => { setNome(e.target.value) }}
                      />
                    </div>
                    <div className="flex items-center py-2 px-3 mb-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                        />
                      </svg>
                      <input
                        type="text"
                        id="on-error-email"
                        className="w-full ml-2 appearance-none border-green-300 h-8 pl-2 border rounded text-base text-gray-700 placeholder-gray-400"
                        name="email"
                        value={email}
                        placeholder="Your email"
                        onChange={(e) => { handleDateEmail(e) }}
                      />
                      {!validEmail ? <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={15}
                        height={15}
                        fill="currentColor"
                        className="absolute right-10 text-red-500"
                        viewBox="0 0 1792 1792"
                      >
                        <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z"></path>
                      </svg>
                        :
                        ''}
                    </div>
                    <div className="flex items-center py-2 px-3 mb-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <input
                        type="password"
                        className="w-full ml-2 appearance-none border-green-300 h-8 pl-2 border rounded text-base text-gray-700 placeholder-gray-400"
                        name="password"
                        placeholder="Password"
                        value={passwordConfirmation}
                        onChange={(e) => { setPasswordConfirmation(e.target.value); }}
                      />
                    </div>
                    <div className="flex items-center py-2 px-3 mb-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <input
                        type="password"
                        className="w-full ml-2 appearance-none border-green-300 h-8 pl-2 border rounded text-base text-gray-700 placeholder-gray-400"
                        name="password"
                        placeholder="Validite password"
                        value={password}
                        onChange={(e) => handlePasswordChange(e)}
                      />

                    </div>
                    <div className="grid w-full h-1 grid-cols-12 gap-4 mt-3">
                      <div className={` h-full col-span-3 ${getStrengthBarColor(1)} bg-gray-200 rounded `}></div>
                      <div className={` h-full col-span-3 ${getStrengthBarColor(2)} bg-gray-200 rounded `}></div>
                      <div className={` h-full col-span-3 ${getStrengthBarColor(3)} bg-gray-200 rounded `}></div>
                      <div className={` h-full col-span-3 ${getStrengthBarColor(4)} bg-gray-200 rounded `}></div>
                    </div>
                    {validPassword && passwordConfirmation === password ?
                      <>
                        <div className="mt-2 text-green-500">Valid password</div>
                      </>
                      :
                      <>
                        <div className="mt-2 text-red-500">Invalid password</div>
                      </>
                    }

                  </div>
                </div>
              </div>
              <div className="p-5">
                <button

                  className="rounded-lg bg-green-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 ring-offset-2 transition hover:bg-green-400 focus:ring-2 md:w-32"
                  onClick={() => { register() }}
                >
                  Create User
                </button>
                <div className="block">
                {showAlert ? <p>{alert}</p> : ''}
                  <small className="block" >
                    {" "}
                    I agree to the{" "}
                    <button className="underline" onClick={() => navigate("/")}>
                      Terms and Conditions
                    </button>
                  </small>
                </div>

              </div>
            </div>

          </>
        }
      />
      <Modal
        show={showModal}
        size={"small"}
        fullscreen={false}
        title={
          <>
            <div className='text-center text-dark'>

              <button
                onClick={() => { clickClose() }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  border: 'none',
                  background: 'none'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </>
        }
        list={
          <>

            <div className='text-center text-dark'>
              {!status ?
                <>
                  {base64 !== "" ? <img
                    className="w-full"
                    src={base64}
                  />
                    :
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  }
                </>
                :
                <div className='text-center text-dark'>
                  <svg
                    className="w-16 h-16 mx-auto text-red-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  The maximum number of phones for your plan has already been registered.
                </div>
              }
              <span className="visually-hidden"></span>

            </div>
          </>
        }
        footer={
          <>
            <br />
          </>
        }
      />
      <Modal
        show={showDisconnect}
        size={"small"}
        fullscreen={false}
        title={
          <>
            <div className='text-center text-dark'>

              <button
                onClick={() => { setShowDisconnect(false) }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  border: 'none',
                  background: 'none'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </>
        }
        list={
          <>
            <div className='text-center text-dark'>
              <div className='text-center text-dark'>
                <svg
                  className="w-16 h-16 mx-auto text-red-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Are you sure you want to unlink your account from WhatsApp?<br />
                <button
                  onClick={() => handleDisconnect()}
                  className="mt-6 rounded-lg bg-green-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 ring-offset-2 transition hover:bg-green-400 focus:ring-2 md:w-32"
                >
                  Yes
                </button>
                <button
                  onClick={() => setShowDisconnect(false)}
                  className="mt-6 ml-4 rounded-lg bg-red-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-red-500 ring-offset-2 transition hover:bg-red-400 focus:ring-2 md:w-32"
                >
                  No
                </button>
              </div>
              <span className="visually-hidden"></span>

            </div>
          </>
        }
        footer={
          <>
            <br />
          </>
        }
      />
      <Modal
        show={modalConfig}
        size={"fullscreen"}
        fullscreen={true}
        title={
          <>
            <div className='text-center text-dark' >
              <h2 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                SETTING UP THE AUTOMATED SERVICE FLOW
              </h2>
              <button
                onClick={() => { setModalConfig(false) }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  border: 'none',
                  background: 'none'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </>
        }
        list={
          <>
            <div className="flex w-full h-full flex-col md:flex-row" style={{ background: "#dad3cc" }}>

              <div className=" border-b md:w-1/4 md:border-r lg:w-1/4 p-4 justify-center items-top" >

                <div className="w-full">
                  <label htmlFor="search" className="flex text-center font-medium text-gray-700">
                    Steps
                  </label>

                  <ul className="mt-1 max-h-120 overflow-auto border border-green-300 rounded-md cursor-pointer ">
                    <li className={`list-group-item pl-3 pr-4 py-2 border-b border-green-200 w-full flex items-center hover:bg-green-100 justify-between text-sm text-gray-800 ${stepsSelected.length === 0 ? "bg-green-100" : "bg-white"}`} onClick={() => { handleClickSelectedStep([]); }}>

                      Add new step flow
                    </li>
                    {steps !== undefined && steps.length > 0 ?
                      steps.map((r: any, key: number) => {
                        return (<>
                          <li className={`list-group-item pl-3 pr-4 py-2 border-b border-green-200 w-full flex items-center hover:bg-green-100 justify-between text-sm text-gray-800 ${r.id === stepsSelected.id ? "bg-green-100" : "bg-white"}`} onClick={() => { handleClickSelectedStep(r) }}>
                            {key + 1} {r.titulo}
                            <button
                              type="submit" onClick={() => { handleRemoveStep(r) }}
                              className="px-2 ml-1 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-dark transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                            >
                              <TrashIcon />
                            </button>
                          </li>
                        </>)
                      })
                      : <></>}
                    {/* Add more items here */}
                  </ul>
                </div>
              </div>

              <div className="w-full border-b md:w-3/4 md:border-r lg:w-3/4 p-4 justify-center items-top" style={{ background: "#dad3cc" }}>
                <div className="w-full lg:ml-8 p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
                  <label htmlFor="Title" className="block text-center font-medium text-gray-700">Title</label>
                  <div className="max-w-sm mx-auto md:w-2/3">
                    <div className=" relative ">
                      <input
                        type="text"
                        id="Title"
                        value={titulos}
                        className="rounded-md shadow-lg flex-1 border border-green-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                        onChange={(e) => setTitulos(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
                  <label htmlFor="Title" className="block text-center font-medium text-gray-700">Start Message</label>
                  <div className="max-w-sm mx-auto md:w-2/3">

                    <textarea id="text-area" className='relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm'
                      value={mensagemsInicio}
                      onFocus={() => setEditing('inicio')}
                      onKeyDown={handleKeyDown}
                      onChange={handleChangeInicio}
                    />

                    <br />

                    <EmojiPicker onEmojiSelect={onEmojiSelectInicio} />

                  </div>

                </div>
                <div className="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
                  <label htmlFor="Title" className="block text-center font-medium text-gray-700">Options</label>
                  <div className="max-w-sm mx-auto space-y-5 md:w-2/3">

                    <label className="flex items-center space-x-3 cursor-pointer">
                      <input
                        type="checkbox"
                        name="checked-demo"
                        className="opacity-0 absolute h-6 w-6"
                        onClick={() => { handleCheckBox() }}
                      />
                      <div className="bg-white border border-green-300 rounded-md h-6 w-6 flex items-center justify-center">
                        {showOption && (
                          <svg className="fill-current text-green-500" width="22" height="22" viewBox="0 0 20 20">
                            <path d="M7.629 15.179L3.822 11.372l1.415-1.414 2.392 2.391 5.656-5.657 1.414 1.415z" />
                          </svg>

                        )}
                      </div>
                      <span className="font-normal text-gray-700 dark:text-white">Menu Mode</span>
                    </label>

                    <div>
                      <div className="flex">
                        <div className="w-64">
                          {countOptions >= 1 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[0] === 0) {
                                    setShowSteps([1, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option1 === "" ? "Select Option 1" : option1} </span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[0] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 1</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption1(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}

                                </ul>
                              </div>
                            </div> : <></>}
                          {countOptions >= 2 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[1] === 0) {
                                    setShowSteps([0, 1, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option2 === "" ? "Select Steps 2" : option2}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[1] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"

                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 2</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption2(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 3 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[2] === 0) {
                                    setShowSteps([0, 0, 1, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option3 === "" ? "Select Steps 3" : option3}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[2] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 3</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption3(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 4 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[3] === 0) {
                                    setShowSteps([0, 0, 0, 1, 0, 0, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option4 === "" ? "Select Steps 4" : option4}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[3] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 4</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption4(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 5 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[4] === 0) {
                                    setShowSteps([0, 0, 0, 0, 1, 0, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option5 === "" ? "Select Steps 5" : option5}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[4] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 5</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption5(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 6 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[5] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 1, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option6 === "" ? "Select Steps 6" : option6}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[5] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 6</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption6(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 7 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[6] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 1, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option7 === "" ? "Select Steps 7" : option7}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[6] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 7</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption7(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 8 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[7] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 1, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                } >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option8 === "" ? "Select Steps 8" : option8}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[7] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 8</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption8(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 9 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[8] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 1, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option9 === "" ? "Select Steps 9" : option9}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[8] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 9</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption9(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 10 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[9] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 1])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option10 === "" ? "Select Steps 10" : option10}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[9] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Select Option 10</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption10(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                </ul>
                              </div>
                            </div>
                            : <></>}
                        </div>
                        {countOptions > 0 ?
                          <button
                            type="submit" onClick={() => { handleCountOption() }}
                            className="px-2 ml-1 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                          >
                            <PlusIcon />
                          </button> : <></>}
                        {countOptions > 1 ?
                          <button
                            type="submit" onClick={() => { handleRemoveOption() }}
                            className="px-2 ml-1 bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                          >
                            <TrashIcon />
                          </button> : <></>}
                      </div>
                    </div>
                    {countOptions >= 1 ?
                      <div className="items-center w-full text-gray-500 md:inline-flex md:space-y-0">
                        <label htmlFor="Title" className="block text-center font-medium text-gray-700">Error Message</label>
                        <div className="max-w-sm mx-auto md:w-2/3">
                          <div className=" relative ">
                            <div>
                              <textarea id="text-area"
                                className='relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm'
                                value={mensagemErro} onChange={(e) => setMensagemErro(e.target.value)} />

                              <EmojiPicker onEmojiSelect={onEmojiSelectErro} />

                            </div>
                          </div>
                        </div>

                      </div>
                      : <></>}
                  </div>
                </div>
                <div className="items-center w-full p-4 text-gray-500 md:inline-flex md:space-y-0">
                  <label htmlFor="Title" className="block text-center font-medium text-gray-700">End Message</label>
                  <div className="max-w-sm mx-auto md:w-2/3">
                    <div className=" relative ">
                      <div>
                        <textarea id="text-area" className='relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm'
                          value={mensagemsFim}
                          onFocus={() => setEditing('fim')}
                          onKeyDown={handleKeyDown}
                          onChange={handleChangeFim} />

                        <EmojiPicker onEmojiSelect={onEmojiSelectFim} />

                      </div>
                    </div>
                  </div>

                </div>
                <div className="w-full px-4 pb-1 ml-auto text-gray-500 md:w-1/3">
                  <button
                    type="submit"
                    className="py-2 px-4  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                    onClick={() => { handleSave() }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className="block h-full w-full p-8 md:w-1/3 lg:w-1/3 flex flex-col justify-top items-top border-b md:border-r" style={{ background: "#dad3cc" }}>
                <div className="w-full justify-start " >
                  <label htmlFor="search" className="block text-center font-medium text-gray-700" style={{ background: "#dad3cc" }}>
                    Response Message
                  </label>
                  <div
                    className="rounded px-3 mt-3"
                    style={{ backgroundColor: "#E2F7CB", width: "250px" }}
                  >
                    <p className="text-sm mt-1 whitespace-pre-line">{mensagemsInicio}</p>
                    <br></br>
                    {option1 !== "" ? <p className="text-sm">1 - {option1}</p> : <></>}
                    {option2 !== "" ? <p className="text-sm">2 - {option2}</p> : <></>}
                    {option3 !== "" ? <p className="text-sm">3 - {option3}</p> : <></>}
                    {option4 !== "" ? <p className="text-sm">4 - {option4}</p> : <></>}
                    {option5 !== "" ? <p className="text-sm">5 - {option5}</p> : <></>}
                    {option6 !== "" ? <p className="text-sm">6 - {option6}</p> : <></>}
                    {option7 !== "" ? <p className="text-sm">7 - {option7}</p> : <></>}
                    {option8 !== "" ? <p className="text-sm">8 - {option8}</p> : <></>}
                    {option9 !== "" ? <p className="text-sm">9 - {option9}</p> : <></>}
                    {option10 !== "" ? <p className="text-sm">10 - {option10}</p> : <></>}
                    <br></br>
                    <p className="text-sm mt-1 whitespace-pre-line">{mensagemsFim}</p>
                    <p className="text-right text-xs text-grey-dark mt-1">
                      xx:xx pm
                    </p>
                  </div>
                </div>
                {countOptions >= 1 ?
                  <div className="w-full justify-start"><br />
                    <label htmlFor="search" className="block text-center font-medium text-gray-700">
                      Option Invalid Message
                    </label><br />
                    <div
                      className="rounded px-3 mt-3"
                      style={{ backgroundColor: "#E2F7CB" }}
                    >
                      <p className="text-sm">{mensagemErro}</p>
                      <p className="text-right text-xs text-grey-dark mt-1">
                        xx:xx pm
                      </p>
                    </div>

                  </div> : <></>}


              </div>

            </div>



          </>
        }
        footer={
          <>

          </>
        }
      />
      <>
        <div id="chat-container" className={`${showChat ? '' : 'hidden'} fixed bottom-0 right-4 w-96`}>
          <div className="bg-white shadow-md rounded-lg max-w-lg w-full">
            <div className="p-4 border-b bg-green-500 text-white rounded-t-lg flex justify-between items-center">
              <div>
                <img
                  className="w-10 h-10 rounded-full"
                  src={`${SelectedContactImage !== undefined ? SelectedContactImage : "./Anoni.jpg"}`}
                />
              </div>
              <div className="ml-4">
                <p className="text-white">{SelectedContactName}</p>

              </div>
              <div>
                {/*   <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                >
                  <path
                    fill="#FFFFFF"
                    d="M15.9 14.3H15l-.3-.3c1-1.1 1.6-2.7 1.6-4.3 0-3.7-3-6.7-6.7-6.7S3 6 3 9.7s3 6.7 6.7 6.7c1.6 0 3.2-.6 4.3-1.6l.3.3v.8l5.1 5.1 1.5-1.5-5-5.2zm-6.2 0c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z"
                  />
                </svg> */}
              </div>
              <div className="ml-6">
                {/*  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                >
                  <path
                    fill="#FFFFFF"
                    d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                  />
                </svg> */}
              </div>

              <div className="ml-6">
                {/*   <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                >
                  <path
                    fill="#FFFFFF"

                    d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                  />
                </svg> */}
              </div>
              <button
                id="close-chat"
                onClick={() => { setShowChat(false) }}
                className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div id="chatbox" className="p-4 h-80 overflow-y-auto">
              {Conversas.length > 0 && Conversas.map((r: any) => {
                return (
                  <>
                    {JSON.parse(r.data)['Type'] === "receveid_message" ?
                      <>
                        {SelectedContact === JSON.parse(r.data)['Body']['Info']['SenderJid'].split('@')[0] ?
                          <>
                            <div className="flex justify-center mb-4" >
                              <div
                                className="rounded py-2 px-4"
                                style={{ backgroundColor: "#FCF4CB" }}
                              >
                                <p className="text-xs">{new Date(r.created_at).toLocaleString('pt-BR')}</p>
                              </div>
                            </div>
                            <div className="flex mb-2" style={{ paddingLeft: "50px" }}>
                              <div
                                className="rounded py-2 px-3"
                                style={{
                                  backgroundColor: "#F2F2F2", width: "250px"
                                }}
                              >
                                <p className="text-sm text-teal">{JSON.parse(r.data)['Body']['Info']['PushName']}</p>
                                <p className="text-sm mt-1">
                                  {wrapText(JSON.parse(r.data)['Body']['Info']["Source"]['message']["conversation"], 32)}
                                </p>
                                <p className="text-right text-xs text-grey-dark mt-1">
                                  {formatLastMessageTime(JSON.parse(r.data)['Body']['Info']["Source"]['messageTimestamp'])}
                                </p>
                              </div>
                            </div>

                          </>
                          :
                          <>

                          </>}
                      </>
                      : <>
                        {JSON.parse(r.data)['Type'] === "send_message" ?
                          <>

                            {SelectedContact === JSON.parse(r.data)['Body']['Info']['RemoteJid'].split('@')[0] ?
                              <>
                                <div className="flex justify-center mb-4" >
                                  <div
                                    className="rounded py-2 px-4"
                                    style={{ backgroundColor: "#FCF4CB" }}
                                  >
                                    <p className="text-xs">{new Date(r.created_at).toLocaleString('pt-BR')}</p>
                                  </div>
                                </div>
                                <div className="flex justify-end mb-2" style={{ width: "250px", }}>
                                  <div
                                    className="rounded py-2 px-3"
                                    style={{ backgroundColor: "#E2F7CB" }}
                                  >
                                    <p className="text-sm mt-1">{renderConversation(JSON.parse(r.data)['Body']['Info']["Source"]['message']["conversation"])}</p>
                                    <p className="text-right text-xs text-grey-dark mt-1">
                                      {formatLastMessageTime(JSON.parse(r.data)['Body']['Info']["Source"]['messageTimestamp'])}
                                    </p>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                              </>
                            }
                          </>
                          :
                          <>
                          </>
                        }
                      </>
                    }

                  </>);
              })}
            </div >
            <div>
              <div className="p-4 border-t flex items-start space-x-2 bg-gray-100">
                <EmojiPicker onEmojiSelect={onEmojiSelectResponse} />
                <textarea
                  placeholder="Type a message"
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 resize-none"
                  rows={1}
                  value={mensagemResponse}
                  onFocus={() => setEditing('response')}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                />
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
                >
                  Send
                </button>
              </div>
              <div className="w-full justify-start">
                <label htmlFor="search" className="block text-center font-medium text-gray-700" style={{ background: "#dad3cc" }}>
                  Response Message
                </label>
                <div className="relative justify-end mb-2">
                  <div
                    className="rounded py-2 px-3"
                    style={{ backgroundColor: "#E2F7CB" }}
                  >
                    <p className="text-sm mt-1 whitespace-pre-line">{mensagemResponse}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );

}

export default Home;