
import React, { useEffect, useState } from 'react';

const DataTable = ({ ...props } ) => {
	
	const [columns, setColumns] = useState([] as any);
    const [data, setData] = useState([] as any);

	useEffect(() => {  
		  setColumns(props['columns']);
		  setData(props['data']);
		console.log(props['data']);
	}, []);

  return (
    <div className="overflow-x-auto custom-scrollbar">
      <div className="inline-block min-w-full align-middle">
        <div className="overflow-hidden shadow h-full">
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <thead className="bg-gray-100">
              <tr>
                { columns.map((col: any) => (
                  <th key={col.accessor} className="p-4 text-xs font-medium text-left text-gray-500 uppercase">
                    {col.Header}
                  </th>
                ))} 
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 h-auto">
            {data.map((component : any, index : any) => (
            <td key={index} className="p-4 space-x-2 whitespace-nowrap">
              {component}
            </td>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DataTable;