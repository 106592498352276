
import React, { useEffect, useState } from 'react';
import './../../../App.css';
import { useLocation } from 'react-router-dom';
import KanbanBoard from '../../../components/KanbanBoard/KanbanBoard';

function Kanban() {


  const location = useLocation();
  useEffect(() => {


  }, []);
  return (
    <>
      <div className="h-screen">
        <div className=" bg-white flex flex-col bg-clip-border rounded-xl m-2">
          <div className="p-4 block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5  ">
            <div className="w-full mb-1">
              <div className="mb-4">
                <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                  My Kanban
                </h1>
              </div>
              <KanbanBoard />
            </div>
          </div>
      </div>
    </div >
    </>
  );


}
export default Kanban;
